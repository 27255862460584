import React from "react";
import { firebase } from "../Firebase.js";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import basillogo from "../images/Basil_menu_left_medium.png";
import basillogoblack from "../images/Basil_menu_left_black.png";
import signinbackground from "../images/signinbackground.jpeg";
import widesigninbackground from "../images/v2signinbackground.jpeg";

import StickyBox from "react-sticky-box";

import { checkUser } from "../FirestoreFunctions.js";

import { Button, Image, GridColumn, Grid, Divider } from "semantic-ui-react";
import { ControlPanel } from "./ControlPanel.js";

class BasilSignIn extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true
    };
  }

  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "redirect",
    credentialHelper: "none",
    // We will display Google and Email as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };

  async Authenticate(myApp) {
    await firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(async function() {
        //console.log(firebase)
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        //return firebase.auth().signInWithEmailAndPassword(email, password);
        firebase.auth().onAuthStateChanged(async function(user) {
          if (user) {
            var u = firebase.auth().currentUser;
            //console.log(u);
            const gcUser = await checkUser(u.email.toLowerCase());
            //console.log(gcUser)
            user.managerof = gcUser.managerof;
            myApp.setState({
              session: user,
              //restaurantuser
              gcUser: gcUser,
              loading: false
            });
          }
        });
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(
          " Firebase Auth Error. Code: " + errorCode + "\n   " + errorMessage
        );
        myApp.setState({
          err: true,
          loading: false
        });
      });
  }

  async componentDidMount() {
    let myApp = this;

    await this.Authenticate(myApp);
    //console.log(this)
    this.setState({
      loading: false
    });
  }
  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const { loading, session, gcUser } = this.state;
    //console.log(gcUser);
    //console.log(session);
    //console.log(loading)
    //console.log(this)
    if (!loading && session && gcUser.managerof.length > 0) {
      // LOGGED IN THANKS FOR BEING A MEMBER RESTAURANT
      return (
        <React.Fragment>
          <StickyBox
            className="ctrlsticky"
            style={{
              zIndex: "9999"
            }}
          >
            <Grid>
              <GridColumn width="4">
                <Image src={basillogo} inline id="ctrlpanelbasillogo" />
              </GridColumn>
              <GridColumn width="8" textAlign="center" verticalAlign="middle">
                <div>
                  <span>{session.displayName}</span>
                  <br />
                  <span>{session.email}</span>
                </div>
              </GridColumn>
              <GridColumn
                width="4"
                textAlign="center"
                className="signoutcolumn"
                verticalAlign="middle"
              >
                <a
                  onClick={() =>
                    firebase
                      .auth()
                      .signOut()
                      .then(this.setState({ session: null }))
                  }
                >
                  Sign Out
                </a>
              </GridColumn>
            </Grid>
          </StickyBox>
          {/** ACTUAL CONTROL PANEL */}
          <ControlPanel managerof={gcUser.managerof} user={session} />
        </React.Fragment>
      );
    } else if (!loading && session) {
      //THANKS FOR SIGNING UP
      return (
        <React.Fragment>
          <div className="mainFragment">
            <Image
              className="signinbackground"
              src={signinbackground}
              wrapped
              centered
            />
            <Image
              className="widesigninbackground"
              src={widesigninbackground}
              wrapped
              centered
            />

            <Grid className="mainGrid">
              <Divider hidden />
              <Grid.Row>
                <div>
                  <p className="logintext">
                    Thank you for signing up with Basil Menu. We'll let you know
                    once features are available for restaurant patrons.
                  </p>
                  <p className="logintext">
                    If you beleive you ended up here by mistake and looking for
                    your restaurant's control panel, reach out to your
                    restaurant admin and make sure they grant you access. You
                    can also email us at{" "}
                    <a href="mailto:info@basil.menu">info@basil.menu</a> and
                    we'll help you out.
                  </p>
                  <Button
                    onClick={() =>
                      firebase
                        .auth()
                        .signOut()
                        .then(this.setState({ session: null }))
                    }
                  >
                    Sign Out
                  </Button>
                </div>
              </Grid.Row>
            </Grid>
          </div>
          <p>THANKS FOR SIGNING UP</p>
          <p>{gcUser.manager}</p>
          <a
            onClick={() =>
              firebase
                .auth()
                .signOut()
                .then(this.setState({ session: null }))
            }
          >
            Sign Out
          </a>
        </React.Fragment>
      );
    } else if (!loading) {
      //SIGN IN PAGE
      return (
        <React.Fragment>
          <div className="mainFragment">
            <Image
              className="signinbackground"
              src={signinbackground}
              wrapped
              centered
            />
            <Image
              className="widesigninbackground"
              src={widesigninbackground}
              wrapped
              centered
            />
            <Grid className="mainGrid">
              <Divider hidden />
              <Grid.Row>
                <div>
                  <p className="logintext">
                    Please sign in below. If you're a restaurant manager, you
                    will be routed your restaurant's control panel. If you're a
                    frequent restaurant patron interested in future promotions,
                    newsletters, and more, simply create an account and we'll
                    make sure you're the first to know once we add more
                    features.
                  </p>
                  <p className="logintext">Thank you for using Basil Menu</p>
                </div>
              </Grid.Row>
              <Grid.Row>
                <GridColumn stretched width="16">
                  <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                  />
                </GridColumn>
              </Grid.Row>
            </Grid>
          </div>
        </React.Fragment>
      );
    } else return null;
  }
}

export { BasilSignIn };
