import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { BasilSignIn } from './ControlPanelHome/SignIn.js'

const Switcher = props => {
    // console.log(props)
    return (
        < main >
            <Switch>
                <Route exact path='/' component={BasilSignIn} />
            </Switch>
        </main >)
}

export { Switcher }