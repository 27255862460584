import React, { Component } from "react";
import {
  titleCase,
  compareValues,
  asyncForEach,
} from "../Functions/Functions.js";
import {
  Input,
  Form,
  Image,
  Radio,
  Button,
  Modal,
  Accordion,
  Icon,
  Segment,
  Divider,
  Grid,
} from "semantic-ui-react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import Dropzone from "react-dropzone";
import swal from "sweetalert";

import {
  getStorageGC,
  putStorageGC,
  putMenuChange,
} from "../FirestoreFunctions.js";
import { EditItem } from "./EditItem.js";

const DragHandle = sortableHandle(() => (
  <span id="draghandle">
    {" "}
    <Icon name="sort" /> {/*&equiv;*/}
  </span>
));

const SortableItem = sortableElement(({ value }) => (
  <li className="SortableItem">
    <DragHandle /> {value}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class BuiltAccordionContent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  async componentDidMount() {
    //activeIndex={activeIndex} index={index} container={items[0]} items={items[1]}
    //console.log(this.props)
    const activeIndex = this.props.activeIndex;
    const index = this.props.index;
    const container = this.props.container;
    const items = this.props.items;
    const structuredmenu = this.props.structuredmenu;
    this.setState({
      activeIndex: activeIndex,
      index: index,
      container: container,
      items: items,
      structuredmenu: structuredmenu,
      pencilIcon: "pencil",
      menuSummaryDisabled: true,
      changedMenuSummary: {},
      storedMenuChanges: {},
      editmodalopen: false,
      currentitem: {},
    });
  }

  static async getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps)
    //console.log(prevState)

    return {
      items: nextProps.items,
      structuredmenu: nextProps.structuredmenustructuredmenu,
      container: nextProps.container,
    };
  }

  async componentDidUpdate(newProps, prevState) {
    //console.log(newProps);
    //console.log(prevState);
    if (newProps.items !== prevState.items) {
      //Perform some operation here
      this.setState({
        items: newProps.items,
        structuredmenu: newProps.structuredmenu,
        container: newProps.container,
      });
    }
  }

  /*
        async componentWillReceiveProps(props) {
            console.log(props)
            const activeIndex = props.activeIndex
            const index = props.index
            const container = props.container
            const items = props.items
            const structuredmenu = props.structuredmenu
            this.setState({
                activeIndex: activeIndex,
                index: index,
                container: container,
                items: items,
                structuredmenu: structuredmenu
            })
    
        }*/

  handleRadioChange = (e, { value }) => this.setState({ itemradio: value });

  //////////////////////////////
  //////////////////////////////  MENU SUMMARY CHANGES
  //////////////////////////////
  async onPencilClick(m, value, v) {
    //console.log(this.props);
    //console.log(this.state);
    if (value.icon === "pencil") {
      this.setState({
        pencilIcon: "save",
        menuSummaryDisabled: false,
        changedMenuSummary: {},
      });
    } else {
      var currcategory = this.props.container;
      var catkey;
      const promises = Object.entries(this.props.menucategories).map(
        (c, index) => {
          if (c[1].group.toLowerCase() === currcategory) {
            catkey = c[0];
          }
        }
      );
      const completedpromise = await Promise.all(promises);

      //console.log(catkey);
      var saveobj = {};

      saveobj.categories = {};
      saveobj.categories[catkey] = {};
      saveobj.categories[catkey].group = this.props.container;
      saveobj.categories[catkey].menu = this.props.xmenu.text;
      saveobj.categories[catkey].sort = this.props.index;
      saveobj.categories[catkey] = this.state.changedMenuSummary[catkey]; //// might need to add in

      await putMenuChange(
        this.props.xrestaurant,
        this.props.xmenu.value,
        saveobj
      );

      this.setState({ pencilIcon: "pencil", menuSummaryDisabled: true });
    }
  }

  async checkCat(c, currcategory, changedMenuSummary, data) {
    if (c[1].group.toLowerCase() === currcategory) {
      changedMenuSummary[c[0]] = {};
      changedMenuSummary[c[0]].summary = data.value;
      return true;
    }
  }

  async menuSummaryChange(event, data) {
    var currcategory = this.props.container;
    const { changedMenuSummary } = this.state;
    const { menucategories } = this.props;
    /////

    /*
    console.log(menucategories)
    await asyncForEach(menucategories, async c => {
      console.log(c)
      if (c[1].group.toLowerCase() === currcategory) {
        changedMenuSummary[c[0]] = {};
        changedMenuSummary[c[0]].summary = data.value;
        console.log(changedMenuSummary);
        console.log(c);
        
        console.log(currcategory);
      }


    })
*/

    const promises = Object.entries(menucategories).map(async (c, index) => {
      const { changedMenuSummary } = this.state;
      const results = await this.checkCat(
        c,
        currcategory,
        changedMenuSummary,
        data
      );
      //console.log(results);
      //  console.log(changedMenuSummary);
      //  console.log(c);

      // console.log(currcategory);
    });
    const completedpromise = await Promise.all(promises);
    console.log(completedpromise);
  }
  //////////////////////////////
  //////////////////////////////
  //////////////////////////////

  onDrop(group, i, key, acceptedFiles) {
    // console.log(group);
    // console.log(i);
    // console.log(key);
    // console.log(acceptedFiles);

    const renamedAcceptedFiles = acceptedFiles.map((file) => {
      var ext = file.name.split(".").slice(-1)[0];
      var ext2 = file.name.split("." + ext).slice(0)[0];
      //  console.log(ext);
      // console.log(ext2);
      return new File([file], `${key}`, { type: file.type });
    }); //.${ext}
    //console.log(renamedAcceptedFiles);
    this.updateThumbnail(renamedAcceptedFiles[0], group, i);
  }

  async updateThumbnail(file, group, i) {
    const { structuredmenu, xrestaurant } = this.state;

    var tempmenu = structuredmenu;
    //console.log(tempmenu[group][i].picurl);
    await swal("Upload new photo?", {
      buttons: {
        cancel: true,
        upload: true,
      },
    })
      .then((value) => {
        if (value === "upload") {
          putStorageGC(xrestaurant, "item", file);
        }
      })
      .then(
        (tempmenu[group][i].picurl = await getStorageGC(
          xrestaurant,
          "ctrlpanel",
          file
        ))
      );
    //console.log(tempmenu[group][i].picurl);

    // const r = window.confirm("Upload new photo?"); if (r == true) { putStorageGC(xrestaurant, 'item', file) }
    //rest item file

    this.setState({ structuredmenu: tempmenu });
  }

  onSortEnd = (name, e) => {
    const { structuredmenu, storedMenuChanges } = this.state;
    //console.log(e);
    const { oldIndex, newIndex, collection } = e;
    if (e.nodes[oldIndex] !== undefined) {
      var itemkey = e.nodes[oldIndex].node.lastChild.textContent;
    } else {
      itemkey = this.props.structuredmenu[this.props.container].length;
    }

    if (oldIndex !== newIndex) {
      for (const item in structuredmenu[name]) {
        var sort = parseInt(structuredmenu[name][item].sort);
        if (
          (sort > oldIndex && sort <= newIndex) ||
          (sort < oldIndex && sort >= newIndex)
        ) {
          if (oldIndex < newIndex) {
            console.log(
              "moved sort: " +
                sort +
                "   - affected by " +
                oldIndex +
                " being moved to " +
                newIndex
            );
            sort -= 1;
            console.log("to sort: " + sort);
          } else {
            console.log(
              "moved sort: " +
                sort +
                "   - affected by " +
                oldIndex +
                " being moved to " +
                newIndex
            );
            sort += 1;
            console.log("to sort: " + sort);
          }
          structuredmenu[name][item].sort = sort;
        }
      }
      var temparr = structuredmenu[name];
      //console.log(structuredmenu);
      //console.log(oldIndex);
      // console.log(newIndex);
      //console.log(name);
      // console.log(structuredmenu[name][oldIndex]);
      structuredmenu[name][oldIndex].sort = newIndex;

      const sortedtemp = temparr.sort(compareValues("sort"));

      var newstruc = structuredmenu;
      newstruc[name] = temparr;

      ////////////
      //console.log(structuredmenu);
      for (const m in structuredmenu[name]) {
        const mi = structuredmenu[name][m];
        if (mi.key !== undefined) {
          storedMenuChanges[mi.key] = mi; //storedMenuChanges[category][mi.key]
        }
      }
      //console.log("----");
      //console.log(structuredmenu);
      //console.log(name);
      // console.log("----");
      // console.log(this.state.storedMenuChanges);
      var saveobj = {};
      saveobj.menuitems = this.state.storedMenuChanges;

      //////////
      //console.log(saveobj);

      if (Object.keys(saveobj.menuitems).length > 0) {
        putMenuChange(this.props.xrestaurant, this.props.xmenu.value, saveobj);
      }

      //this.props.storeMenuChanges(structuredmenu,name)
      this.setState({
        structuredmenu: newstruc,
      });
    }
  };

  storeSnapshot = async (newItem, edits, formerCategory) => {
    // console.log(newItem);
    // console.log(edits);
    // console.log(formerCategory);
    // console.log(this.state);
    // console.log(this.props);
    const { item } = this.state;
    if (newItem.status === undefined) {
      newItem.status = "active";
    }

    if (this.state.closeaction === "save") {
      Object.assign(newItem, edits);
      const firestoresave = this.props.handleSavedItem(
        newItem,
        "edit",
        formerCategory
      );

      // console.log(firestoresave);
    } else if (this.state.closeaction === "delete") {
      if (formerCategory === undefined) {
        formerCategory = newItem.group;
      }
      const firestoresave = this.props.handleSavedItem(
        newItem,
        "delete",
        formerCategory
      );

      //console.log(firestoresave);
    }

    //console.log(this.state);
    //console.log(this.props);
    //console.log(newItem);
    this.setState({ snapshotitem: newItem, closeaction: null });
  };

  closeedit = async (e, d) => {
    //
    //console.log(e);
    //console.log(d);
    if (d.id === "saveedit") {
      this.setState({ closeaction: "save" });
      //console.log("save");
    } else if (d.id === "canceledit") {
      //console.log("cancel");
    } else if (d.id === "deleteedit") {
      this.setState({ closeaction: "delete" });
      //console.log("delete");
    }
    this.setState({ editmodalopen: false });
    //console.log(this.state.snapshotitem);
  };

  openEditModal = async (item) => {
    //console.log(item);
    this.setState({ editmodalopen: true, currentitem: item });
  };

  render() {
    //activeIndex={activeIndex} index={index} container={items[0]} items={items[1]} />
    const {
      activeIndex,
      index,
      container,
      items,
      editmodalopen,
      currentitem,
    } = this.state;

    //console.log(index)
    //console.log(container)
    //console.log(items);
    if (items !== undefined) {
      try {
        return (
          // <React.Fragment>
          //{//activeIndex === index ?
          <React.Fragment>
            <Input
              action={{
                icon: this.state.pencilIcon,
                onClick: this.onPencilClick.bind(this),
              }}
              disabled={this.state.menuSummaryDisabled}
              placeholder="Enter a summary..."
              defaultValue={items[items.length - 1]}
              onChange={this.menuSummaryChange.bind(this)}
            ></Input>
            <p>Sorting changes are auto-saved</p>
            <SortableContainer
              key={"sl" + index}
              lockAxis="y"
              onSortEnd={this.onSortEnd.bind(this, container)}
              useDragHandle
              useWindowAsScrollContainer
              helperClass="SortableHelper"
            >
              <ul className="SortableList">
                {items.map((item, i) =>
                  i !== items.length - 1 && item.key !== undefined ? (
                    <SortableItem
                      key={item.key}
                      value={
                        <React.Fragment>
                          <Modal
                            image
                            open={this.state.editmodalopen}
                            onOpen={this.openEditModal.bind(this, item)}
                            trigger={
                              <Button
                                inverted
                                className="itembutton"
                                key={item.key}
                              >
                                {titleCase(item.item)}
                              </Button>
                            }
                            className="edititemmodal"
                          >
                            <Modal.Header>{currentitem.item}</Modal.Header>

                            <EditItem
                              item={currentitem}
                              catSet={this.props.catSet}
                              xrestaurant={this.props.xrestaurant}
                              storeSnapshot={this.storeSnapshot.bind(this)}
                              xmenu={this.props.xmenu.value}
                            />
                            <Modal.Actions>
                              <Button
                                basic
                                color="red"
                                className="edititemactionbutton"
                                id="deleteedit"
                                onClick={this.closeedit.bind(this)}
                              >
                                <Icon name="ban" /> Delete{" "}
                              </Button>
                              <Button
                                basic
                                color="grey"
                                className="edititemactionbutton"
                                id="canceledit"
                                onClick={this.closeedit.bind(this)}
                              >
                                <Icon name="close" /> Cancel{" "}
                              </Button>
                              <Button
                                basic
                                color="green"
                                className="edititemactionbutton"
                                id="saveedit"
                                onClick={this.closeedit.bind(this)}
                              >
                                <Icon name="checkmark" /> Save{" "}
                              </Button>
                            </Modal.Actions>
                          </Modal>
                          <span className="keyspan">{item.key}</span>
                        </React.Fragment>
                      }
                      index={i}
                      collection={index}
                    />
                  ) : null
                )}
              </ul>
            </SortableContainer>
          </React.Fragment>
          //:
          // 'Accordion Not Active. Rerender Required for Drag & Drop Functionality'}

          //</React.Fragment>
          /*:*/
        );
      } catch (error) {
        console.log(error);
        return "Error. Contact Basil";
      }
    } else return null;
  }
}

export { BuiltAccordionContent };
