import React from "react";
import { firebase } from "../Firebase.js";

import Dropzone from 'react-dropzone';
import csv from 'csv';


import {
    Container,
    Button, Icon
} from "semantic-ui-react";

const firestore = firebase.firestore();



/*const batchcategories = [
    '+contact',
    '+address',
    'openinghours(24)',
    'disclaimer',
    'about',
    'logofilename',
    'generalphoto',
    'website',
    'instagram',
    'facebook',
    'twitter',
    '+menus',
    '+categories',
    '+menuitems'
]*/


const getDataStructureGC = async () => {
    const gRead = firestore.doc("admin/datastructure");
    var catsdata
    await gRead.get().then(async doc => {
        if (!doc.exists) {
            console.log("No structure document!");
        } else {
            catsdata = await doc.data();

        }
    });
    //console.log(catsdata)
    return catsdata;
};


const submitPublicDataGC = async (submitArray, xrestaurant) => {
    //prefix from state username
    const prefix = xrestaurant.substring(0, 2);

    //INTERNAL
    //const gInternal = firestore.doc("clientmasters/internal/"+PREFIX2+RESTAURANT));
    const gInternal = firestore.doc("clientmasters/internal/" + prefix + "/" + xrestaurant);
    //const gInternal = firestore.doc("clientmasters/internal/wo/woodbines");
    await gInternal.set(submitArray[0], { merge: true });

    //PUBLIC
    //const gClient = firestore.doc("clientmasters/public/wo/"+PREFIX2+RESTAURANT);
    const gRestaurant = firestore.doc("clientmasters/public/" + prefix + "/" + xrestaurant);
    await gRestaurant.set(submitArray[1], { merge: true });

    //MENUS
    //const gMenus = firestore.doc("clientmasters/public/wo/"+PREFIX2+RESTAURANT+"/MENUS");

    for (const m in submitArray[2]) {
        console.log(m)
        const gMenus = firestore.doc("clientmasters/public/" + prefix + "/" + xrestaurant + "/menus/" + m);
        await gMenus.set(submitArray[2][m], { merge: true });
    }



    //PUBLIC


    //PUBLIC


};

const clearEmpties = async (o) => {
    for (var k in o) {
        if (!o[k] || typeof o[k] !== "object") {
            continue // If null or not an object, skip to the next iteration
        }

        // The property is an object
        await clearEmpties(o[k]); // <-- Make a recursive call on the nested object
        if (Object.keys(o[k]).length === 0) {
            delete o[k]; // The object had no properties, so delete that property
        }
    }
    return o
}



class BatchUpload extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true
        };
        this.uploadClick = this.uploadClick.bind(this);
    }

    async componentDidMount() {
        const catsdata = await getDataStructureGC()
        //console.log(catsdata)
        //console.log(catsdata.batchcategories)


        this.setState({
            loading: false,
            catsdata: catsdata
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log(nextProps)
        //  console.log(prevState)

        return {
            xrestaurant: nextProps.xrestaurant
        }
    }

    async componentDidUpdate(newProps, prevState) {
        // console.log(newProps)
        // console.log(prevState)
        if (newProps.xrestaurant !== prevState.xrestaurant) {
            //Perform some operation here
            this.setState({
                xrestaurant: newProps.xrestaurant,
            });
        }
    }






    uploadClick = async (e, { name }) => {
        const { submitArray } = this.state


        if (submitArray !== undefined) {
            console.log(name)
            console.log(submitArray)
            await submitPublicDataGC(submitArray, this.props.xrestaurant)


            this.setState({ obj: undefined })
        }

    }




    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {

    }



    onDrop(files) {
        console.log(this.state)
        const { catsdata } = this.state
        const batchcategories = catsdata.batchcategories
        const subcategories = catsdata.subcategories
        var submitArray = []
        var restaurantobj = {};
        var menuobj = {};
        var internalobj = {};
        var file = files[0];
        console.log(file)
        const reader = new FileReader();
        reader.onload = () => {
            csv.parse(reader.result, async (err, data) => {

                for (var bc = 0; bc < batchcategories.length; bc++) {
                    var ii = data[0].indexOf(batchcategories[bc])

                    if (batchcategories[bc].charAt(0) !== '+' && data[1][ii] !== undefined) {
                        if (data[1][ii] !== '') {
                            if (data[1][ii].toLowerCase() === 'true' || data[1][ii].toLowerCase() === 'false') {
                                const isTrueSet = (data[1][ii].toLowerCase() === 'true');
                                restaurantobj[batchcategories[bc]] = isTrueSet
                            } else {
                                restaurantobj[batchcategories[bc]] = data[1][ii]
                            }

                        }
                    } else if (batchcategories[bc].charAt(0) === '+') {
                        const batchcat = batchcategories[bc].substring(1).toLowerCase()
                        var key = ''
                        var pre = ''
                        if (batchcat === 'menus') {
                            key = 'menu'
                            pre = 'm:'
                        } else if (batchcat === 'menuitems') {
                            key = 'itemmenu'
                            pre = 'i:'
                        } else if (batchcat === 'categories') {
                            key = 'catmenu'
                            pre = 'g:'
                        } else if (batchcat === 'contacts') {
                            key = 'email'
                            pre = 'c:'
                        } else if (batchcat === 'address') {
                            key = 'address'
                            pre = 'a:'
                        } else { pre = 'x:' }
                        const keyvalue = data[0][data[0].indexOf(key)]

                        restaurantobj[batchcat] = {}
                        internalobj[batchcat] = {}
                        menuobj[batchcat] = {}
                        //foreach data.length j
                        for (var r = 1; r < data.length; r++) {
                            //foreach subcategory (stored online?) within batchcategories[bc] sc
                            const id = data[r][data[0].indexOf(pre + 'id')]
                            for (const sci in subcategories) {
                                for (var ssci = 0; ssci < subcategories[sci].length; ssci++) {

                                    const head = subcategories[sci][ssci]
                                    const i = data[0].indexOf(pre + head)
                                    if (i !== -1) {
                                        if (data[r][i] !== "" && data[r][i] !== undefined) {
                                            if (data[0][i].charAt(0) === 'c') {
                                                if (internalobj[batchcat][id] === undefined) {//[data[r][i]]
                                                    internalobj[batchcat][id] = {}//[data[r][i]]
                                                }
                                                internalobj[batchcat][id][head] = data[r][i].toLowerCase()
                                            } else if (data[0][i].charAt(0) === 'g' || data[0][i].charAt(0) === 'i') {
                                                const menuholder = data[r][data[0].indexOf(pre + 'menu')].replace(/\s/g, '_').toLowerCase()
                                                console.log(menuholder)
                                                if (menuobj[menuholder] === undefined) {//[data[r][i]]
                                                    menuobj[menuholder.toLowerCase()] = {}//[data[r][i]]
                                                }
                                                if (menuobj[menuholder][batchcat] === undefined) {//[data[r][i]]
                                                    menuobj[menuholder][batchcat] = {}//[data[r][i]]
                                                }

                                                if (menuobj[menuholder][batchcat][id] === undefined) {//[data[r][i]]
                                                    menuobj[menuholder][batchcat][id] = {}//[data[r][i]]
                                                }
                                                if (pre + head === 'i:labels') {
                                                    data[r][i] = data[r][i].toLowerCase().split("^")
                                                    console.log(data[r][i])
                                                    menuobj[menuholder][batchcat][id][head.toLowerCase()] = data[r][i]
                                                } else {
                                                    console.log(data[r][i])
                                                    console.log(menuobj)
                                                    console.log(menuholder)
                                                    console.log(batchcat)
                                                    console.log(id)
                                                    console.log(head)
                                                    menuobj[menuholder][batchcat][id][head.toLowerCase()] = data[r][i]
                                                }


                                            } else {
                                                if (restaurantobj[batchcat][id] === undefined) {//[data[r][i]]
                                                    restaurantobj[batchcat][id] = {}//[data[r][i]]
                                                }
                                                if (data[r][i].toLowerCase() === 'true' || data[r][i].toLowerCase() === 'false') {
                                                    const isTrueSet = (data[r][i].toLowerCase() === 'true');
                                                    restaurantobj[batchcat][id][head.toLowerCase()] = isTrueSet
                                                } else {
                                                    restaurantobj[batchcat][id][head.toLowerCase()] = data[r][i]
                                                }

                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                };
                console.log(restaurantobj)
                restaurantobj = await clearEmpties(restaurantobj)
                internalobj = await clearEmpties(internalobj)
                menuobj = await clearEmpties(menuobj)
                submitArray.push(internalobj, restaurantobj, menuobj);
                console.log(submitArray)
            })
        }
        reader.readAsBinaryString(file);
        this.setState({
            submitArray: submitArray
        })
    }




    render() {
        const { loading, xrestaurant } = this.state;

        const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };
        const fontSize = 5;


        if (!loading && xrestaurant !== undefined) {
            return (
                <Container fluid className='mainCont'>

                    <div className="dropzonediv">
                        <Dropzone onDrop={this.onDrop.bind(this)}>
                            {({ getRootProps, getInputProps, isDragActive,
                                isDragAccept,
                                isDragReject }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />

                                        <span>{isDragActive ? "📂Drop file here to upload." : "📁Click here to upload a file"}</span>

                                    </div>
                                )}
                        </Dropzone>
                    </div>
                    <Button name='upload' size='small' floated='left' animated='vertical' onClick={this.uploadClick}>
                        <Button.Content hidden>Confirm</Button.Content>
                        <Button.Content visible>
                            Upload
                        </Button.Content>
                    </Button>
                </Container >
            );
        } else if (!loading && xrestaurant === undefined) {
            return "Select a Restaurant First"
        } else return null
    }
}

export { BatchUpload };