/*import { firebase } from "./Firebase.js";
const firestore = firebase.firestore();

const getRestaurantGC = async (xrestaurant) => {
    const prefix = xrestaurant.substring(0, 2);
    const gRead = firestore.doc("clientmasters/public/" + prefix + "/" + xrestaurant);
    var restdoc
    await gRead.get().then(async doc => {
        if (doc.exists) {
            restdoc = doc.data();
        } else {
            console.log("No restaurant document!");
            restdoc = null
        }
    });
    return restdoc;
};

export { getRestaurantGC }*/

import { firebase } from "./Firebase.js";
const firestore = firebase.firestore();
const storage = firebase.storage();

const getInternalGC = async (xrestaurant) => {
  const prefix = xrestaurant.substring(0, 2);
  //console.log(xrestaurant)
  //console.log(prefix)
  const gRead = firestore.doc(
    "clientmasters/internal/" + prefix + "/" + xrestaurant
  ); //will replace clients with prefix on firestore.
  var restdoc;
  await gRead.get().then(async (doc) => {
    if (!doc.exists) {
      console.log("No restaurant document!");
      restdoc = null;
    } else {
      restdoc = await doc.data();
    }
  });
  return restdoc;
};

const getRestaurantGC = async (xrestaurant) => {
  const prefix = xrestaurant.substring(0, 2);
  //console.log(xrestaurant)
  //console.log(prefix)
  const gRead = firestore.doc(
    "clientmasters/public/" + prefix + "/" + xrestaurant
  ); //will replace clients with prefix on firestore.
  var restdoc;
  await gRead.get().then(async (doc) => {
    if (!doc.exists) {
      console.log("No restaurant document!");
      restdoc = null;
    } else {
      restdoc = doc.data();
    }
  });
  return restdoc;
};

const getMenuGC = async (xrestaurant, xmenu) => {
  const prefix = xrestaurant.substring(0, 2);
  const gRead = firestore.doc(
    "clientmasters/public/" + prefix + "/" + xrestaurant + "/menus/" + xmenu
  ); //will replace clients with prefix on firestore.
  var menudoc;
  await gRead.get().then(async (doc) => {
    if (!doc.exists) {
      console.log("No restaurant document!");
      menudoc = "RestaurantNotFound";
    } else {
      menudoc = doc.data();
    }
  });
  return menudoc;
};

const deleteFirestoreField = async (
  xrestaurant,
  field,
  restdatachange,
  xmenu,
  type
) => {
  //console.log(xrestaurant);
  //console.log(field);
  //console.log(restdatachange);
  //console.log(xmenu);
  //console.log(type);

  var updates = {};
  const prefix = xrestaurant.substring(0, 2);
  if (restdatachange === true) {
    var docRef = firestore
      .collection("clientmasters/public/" + prefix + "/")
      .doc(xrestaurant);
  } else {
    docRef = firestore
      .collection(
        "clientmasters/public/" + prefix + "/" + xrestaurant + "/menus/"
      )
      .doc(xmenu);
    updates[`${type}.${field}`] = firebase.firestore.FieldValue.delete();
  }

  // Remove the 'capital' field from the document
  var removeField = await docRef.update(
    updates
  ); /*docRef.update({
    [field]: firebase.firestore.FieldValue.delete()
  });*/
  //console.log(removeField);
};

/*
const putMenuItem = async (xrestaurant, xmenu, item) => {
  try {
    console.log(item)
    var newData = {};
    newData.menuitems = {};
    newData.menuitems[item.key] = item;
    const prefix = xrestaurant.substring(0, 2);
    const gWrite = firestore.doc(
      "clientmasters/public/" + prefix + "/" + xrestaurant + "/menus/" + xmenu
    );
    const result = await gWrite.set(newData, { merge: true }).then(() => {
      return true;
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};*/

const putMenuChange = async (xrestaurant, xmenu, newData) => {
  //console.log(newData);
  try {
    const prefix = xrestaurant.substring(0, 2);
    const gWrite = firestore.doc(
      "clientmasters/public/" + prefix + "/" + xrestaurant + "/menus/" + xmenu
    );
    const result = await gWrite.set(newData, { merge: true }).then(() => {
      return true;
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

const getDoc = async (doc) => {
  return [doc.id, doc.data()];
};

const getMenuListGC = async (xrestaurant) => {
  const prefix = xrestaurant.substring(0, 2);
  let menuRef = firestore.collection(
    "clientmasters/public/" + prefix + "/" + xrestaurant + "/menus"
  );
  var returnObj = {};
  await menuRef
    .get()
    .then((snapshot) => {
      snapshot.forEach(async (doc) => {
        const a = await getDoc(doc);
        //console.log(await getDoc(doc))
        //  console.log(doc.id, '=>', doc.data());
        //returnArr.push([doc.id, doc.data()])
        returnObj[a[0]] = a[1];
        //.push(await getDoc(doc))
      });
    })
    .catch((err) => {
      console.log("Error getting documents", err);
    });
  //console.log(returnArr)
  return returnObj;
};

const getStorageGC = async (xrestaurant, type, path, menu) => {
  const storageRef = storage.ref();
  var pathReference;
  if (type === "logo" || type === "about" || type === "pop") {
    pathReference = storageRef.child(
      "basilmenu/" + xrestaurant + "/" + xrestaurant + "_" + type
    );
  } else if (type === "ctrlpanel") {
    pathReference = storageRef.child("basilmenu/" + xrestaurant + "/" + path);
  } else if (type === "item") {
    pathReference = storageRef.child(
      "basilmenu/" + xrestaurant + "/items/" + menu + "/" + path
    );
  }

  //const pathReference = storage.ref("clients" + slash + "/logo.png");
  var imgurl;

  await pathReference
    .getDownloadURL()
    .then(async function (url) {
      imgurl = url;
    })
    .catch(function (error) {
      // Handle any errors
      console.log(error);
    });
  //console.log(imgurl)
  return imgurl;
};

const putStorageGC = async (xrestaurant, type, file, xmenu, itemid) => {
  const storageRef = storage.ref();
  var pathReference;
  if (type === "logo" || type === "about" || type === "pop") {
    pathReference = storageRef.child(
      "basilmenu/" + xrestaurant + "/" + xrestaurant + "_" + type
    );
  } else if (type === "item") {
    var ext = file.name.split(".").slice(-1)[0];
    var ext2 = file.name.split("." + ext).slice(0)[0];

    pathReference = storageRef.child(
      "basilmenu/" + xrestaurant + "/items/" + xmenu + "/" + itemid
    );
  } else if (type === "menu") {
    pathReference = storageRef.child(
      "basilmenu/" +
        xrestaurant +
        "/menus/" +
        xrestaurant +
        "_" +
        xmenu +
        ".pdf"
    );
    console.log(pathReference);
  }
  //console.log(file)

  //var file = ... // use the Blob or File API
  pathReference.put(file).then(function (snapshot) {
    //console.log('Uploaded a blob or file!');
  });
};

const getStoragePDFGC = async (xrestaurant, xmenu, pdf) => {
  if (pdf) {
    const storageRef = storage.ref();
    var pathReference = storageRef.child(
      "basilmenu/" +
        xrestaurant +
        "/menus/" +
        xrestaurant +
        "_" +
        xmenu +
        ".pdf"
    );
    //console.log(pathReference)
    //const pathReference = storage.ref("clients" + slash + "/logo.png");
    var pdfurl;
    await pathReference
      .getDownloadURL()
      .then(async function (url) {
        pdfurl = url;
      })
      .catch(function (error) {
        // Handle any errors
        console.log(error);
      });
    //console.log(pdfurl)
    return pdfurl;
  }
};

const savePublicGC = async (xrestaurant, newData) => {
  const prefix = xrestaurant.substring(0, 2);

  //console.log(xrestaurant)
  const gPublic = firestore.doc(
    "clientmasters/public/" + prefix + "/" + xrestaurant
  );
  //console.log(newData)
  const result = await gPublic.set(newData, { merge: true }).then(() => {
    return true;
  });

  return result;
};

const getItemListGC = async (xrestaurant, menu) => {
  const storageRef = storage.ref();
  const listRef = storageRef.child(
    "basilmenu/" + xrestaurant + "/items/" + menu
  );

  var iref = [];
  // Find all the prefixes and items.
  await listRef
    .listAll()
    .then(function (res) {
      //console.log(res.items)
      res.items.forEach(function (itemRef) {
        // All the items under listRef.
        const idstart = itemRef.fullPath.indexOf("items/") + 6;
        const ext = itemRef.fullPath.indexOf(".");
        // console.log(ext)
        var itemid = itemRef.fullPath.substring(idstart);
        if (ext !== -1) {
          itemid = itemRef.fullPath.substring(idstart, ext); //switch to .menu
        }

        iref.push([itemid, itemRef.fullPath]);
      });
    })
    .catch(function (error) {
      // Uh-oh, an error occurred!
      console.log(error);
    });
  //console.log(iref)
  return iref;
};

//////////// USER MANAGEMENT //////////
const checkUser = async (email) => {
  const prefix2 = email.substring(0, 2);
  const prefix4 = email.substring(0, 4);
  const gRead = firestore.doc("users/" + prefix2 + "/" + prefix4 + "/" + email);
  var userdata;
  var newUserData = {};
  await gRead.get().then(async (doc) => {
    if (!doc.exists) {
      console.log("No structure document!");
    } else {
      userdata = doc.data();
    }
  });
  if (userdata !== undefined) {
    newUserData.email = userdata.email;
    newUserData.manager = userdata.manager;
    newUserData.managerof = [];
    for (const m in userdata.managerof) {
      if (userdata.managerof[m] === true) {
        newUserData.managerof.push(m);
      }
    }
  } else {
    newUserData.managerof = [];
  }
  // console.log(newUserData)

  return newUserData;
};

const updateUserAccess = async (email, xrestaurant, newstatus) => {
  //if (email !== null && email !== undefined && email !== '') {
  //console.log(newstatus)
  const prefix2 = email.substring(0, 2);
  const prefix4 = email.substring(0, 4);
  const gUser = firestore.doc("users/" + prefix2 + "/" + prefix4 + "/" + email);
  const upload = {};
  upload.email = email;
  upload.managerof = {};
  upload.managerof[xrestaurant] = newstatus;
  //console.log(upload)
  await gUser.set(upload, { merge: true }).then(console.log("successful"));
  return true;
  //return userdata;
  //}
};

const updateRestaurantManagers = async (email) => {
  const prefix2 = email.substring(0, 2);
  const prefix4 = email.substring(0, 4);
  const gRead = firestore.doc("users/" + prefix2 + "/" + prefix4 + "/" + email);
  var userdata;
  await gRead.get().then(async (doc) => {
    if (!doc.exists) {
      console.log("No structure document!");
    } else {
      userdata = doc.data();
    }
  });
  //console.log(userdata)
  return userdata;
};

const updateInternalGC = async (xrestaurant, internaldata, merge) => {
  const prefix = xrestaurant.substring(0, 2);
  //console.log(xrestaurant)
  // console.log(prefix)
  console.log(internaldata);
  const gInternal = firestore.doc(
    "clientmasters/internal/" + prefix + "/" + xrestaurant
  ); //will replace clients with prefix on firestore.
  const result = await gInternal
    .set(internaldata, { merge: merge })
    .then(() => {
      return true;
    });

  return result;
};

export {
  getInternalGC,
  deleteFirestoreField,
  getRestaurantGC,
  getMenuGC,
  getStorageGC,
  getStoragePDFGC,
  getItemListGC,
  getMenuListGC,
  putStorageGC,
  savePublicGC,
  checkUser,
  updateUserAccess,
  updateInternalGC,
  updateRestaurantManagers,
  putMenuChange,
};
