import React, { Component } from "react";
import {
  Form,
  Radio,
  Grid,
  Segment,
  Divider,
  Image,
  Dropdown,
  Modal,
  Button,
  Icon,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { titleCase } from "../Functions/Functions.js";
import Dropzone from "react-dropzone";
import foodpic from "../images/food.jpg";
import applogo from "../images/applogo.png";
import { getStorageGC, putStorageGC } from "../FirestoreFunctions.js";
import { handleImageUpload } from "../Functions/MediaFunctions.js";

const buildCategoryDropdown = async (catSet) => {
  try {
    var catdropdown = [];
    catSet.map((c, index) =>
      catdropdown.push({
        key: c,
        value: c,
        text: titleCase(c), // add a menukey to restdata and merry them up to get spacing
      })
    );
    //console.log(catSet);
    //console.log(catdropdown);
    return catdropdown;
  } catch (error) {
    console.log(error);
  }
};

class EditItem extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      itemradio: this.props.item.status,
      newItem: {},
      latestCategory: this.props.item.group,
    };
  }

  async componentDidMount() {
    var img = await getStorageGC(
      this.props.xrestaurant,
      "item",
      this.props.item.key,
      this.props.xmenu
    );
    //console.log(img);
    //console.log(this.props.item);
    //console.log(this.props);

    if (img === undefined) {
      img = foodpic;
    }

    if (this.props.item.labels !== undefined) {
      var labels = this.props.item.labels.join("\n");
    }

    this.setState({
      xmenu: this.props.xmenu,
      img,
      labels,
    });
  }
  async componentWillUnmount() {
    const sendsnapshot = await this.props.storeSnapshot(
      this.props.item,
      this.state.newItem,
      this.state.formerCategory
    );
  }

  static async getDerivedStateFromProps(nextProps, prevState) {
    //console.log(nextProps);
    //console.log(prevState);

    return {
      xmenu: nextProps.xmenu,
      catSet: nextProps.catSet,
      // itemradio: nextProps.item.radio
    };
  }

  async componentDidUpdate(newProps, prevState) {
    //console.log(newProps);
    //console.log(prevState);
    if (newProps.xmenu !== prevState.xmenu) {
      //Perform some operation here
      this.setState({
        xmenu: newProps.xmenu,
      });
    }
    if (newProps.item.status !== prevState.controlitemradio) {
      //Perform some operation here
      this.setState({
        controlitemradio: newProps.item.status,
        itemradio: newProps.item.status,
      });
    }
    if (newProps.catSet !== prevState.catSet) {
      //Perform some operation here
      const catdropdown = await buildCategoryDropdown(newProps.catSet);
      //console.log(catdropdown);
      //console.log(newProps.catdropdown);
      this.setState({
        catSet: newProps.catSet,
        catdropdown: catdropdown,
      });
    }
  }

  handleDropChange = (e, { value }) => {
    //console.log(this.props);
    //console.log(this.state);
    //console.log(value);
    const { newItem, latestCategory } = this.state;
    newItem.group = value;
    this.setState({ formerCategory: latestCategory });
    this.setState({ latestCategory: value, newItem });
    //console.log(this.state.formerCategory);
    // console.log(this.state);
  };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      catdropdown: [
        { key: value, value: value, text: titleCase(value) },
        ...prevState.catdropdown,
      ],
    }));
  };

  handleRadioChange = (e, { value }, n) => {
    const { newItem } = this.state;
    newItem.status = value;
    //console.log(e);
    // console.log(value);
    //console.log(n);
    this.setState({ itemradio: value, newItem });
  };

  async onDrop(acceptedFiles, rejectedFiles) {
    //console.log(this.props);
    //console.log(acceptedFiles);
    //console.log(rejectedFiles);

    var compressingimage = await this.imgLoader(true);
    this.setState({ compressingimage });
    const resized = await handleImageUpload(acceptedFiles[0]);
    //console.log(resized)
    putStorageGC(
      this.props.xrestaurant,
      "item",
      resized,
      this.props.xmenu,
      this.props.item.key
    )
      .then(() => {
        /*if (id === 'logo') {
            t.setState({ logoURL: URL.createObjectURL(acceptedFiles[0]) })
        } else if (id === 'about') {
            t.setState({ aboutURL: URL.createObjectURL(acceptedFiles[0]) })
        } else if (id === 'pop') {
            t.setState({ popURL: URL.createObjectURL(acceptedFiles[0]) })
        }*/
      })
      .then(async () => {
        var compressingimage = await this.imgLoader(false);
        this.setState({
          compressingimage,
          img: URL.createObjectURL(acceptedFiles[0]),
        });
      });
    /*
    const renamedAcceptedFiles = acceptedFiles.map(file => {
      var ext = file.name.split(".").slice(-1)[0];
      var ext2 = file.name.split("." + ext).slice(0)[0];
      console.log(ext);
      console.log(ext2);
      return new File([file], `${key}`, { type: file.type });
    }); //.${ext}
    console.log(renamedAcceptedFiles);
    this.updateThumbnail(renamedAcceptedFiles[0], group, i);*/
  }

  onAddChange = ({ target }) => {
    //console.log(target.value);
    //console.log(target.id);
    //console.log(target.checked);
    const { newItem } = this.state;
    if ((target.id === "labels") & (target.value !== "")) {
      var val = target.value.split("\n");
    } else if (target.value !== "") {
      val = target.value;
    }
    if (val !== undefined) {
      newItem[target.id] = val;
      //console.log(newItem);
      this.setState({ newItem: newItem });
    }
  };

  async imgLoader(loading) {
    if (loading) {
      return (
        <Dimmer active page>
          <Loader content="Compressing image..." />
        </Dimmer>
      );
    } else return null;
  }

  render() {
    const {
      labels,
      img,
      catSet,
      catdropdown,
      latestCategory,
      newItem,
      compressingimage,
    } = this.state;
    const { item } = this.props;
    //console.log(item);
    //console.log(newItem)
    return (
      <React.Fragment>
        <Modal.Content id="additemcontent">
          <Form id="itemform" key="itemform">
            <Segment id="additemsegment">
              <Grid unstackable padded>
                <Grid.Row stackable>
                  <Grid.Column stretched>
                    <p className="newItemHeaders">
                      Title{" "}
                      <Icon
                        className="asterisk"
                        name="asterisk"
                        size="tiny"
                        color="red"
                      />
                    </p>
                    <Form.Input
                      id="item"
                      onBlur={this.onAddChange.bind(this)}
                      className="additeminput"
                      placeHolder="Title"
                      defaultValue={item.item}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row stackable>
                  <Grid.Column width={16}>
                    <p className="newItemHeaders">Price</p>
                    <Form.Input
                      id="price"
                      onBlur={this.onAddChange.bind(this)}
                      className="additeminput"
                      placeHolder="Price (do not inclue dollar sign)"
                      defaultValue={item.price}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column stretched>
                    <p className="newItemHeaders">Description</p>
                    <Form.TextArea
                      id="description"
                      onBlur={this.onAddChange.bind(this)}
                      icon="pencil"
                      className="additeminput"
                      placeHolder="Item Description"
                      defaultValue={item.description}
                    ></Form.TextArea>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row unstackable divided="vertically">
                  <Grid.Column unstackable width={8}>
                    <p className="newItemHeaders">Labels</p>
                    <Form.TextArea
                      id="labels"
                      onBlur={this.onAddChange.bind(this)}
                      rows={5}
                      className="additeminput"
                      defaultValue={labels}
                      placeholder="Separate Labels With Linebreaks"
                    ></Form.TextArea>
                  </Grid.Column>
                  <Divider />
                  <Grid.Column width={8}>
                    <p
                      className="availabilitycontrolheader"
                      className="newItemHeaders"
                    >
                      Item Availability
                    </p>
                    <Form.Field className="availabilitycontrol">
                      <Radio
                        label="Active"
                        name="radioGroup"
                        value="active"
                        checked={this.state.itemradio === "active"}
                        onChange={this.handleRadioChange}
                      />
                    </Form.Field>
                    <Form.Field className="availabilitycontrol">
                      <Radio
                        label="Show As Unavailable"
                        name="radioGroup"
                        value="unavailable"
                        checked={this.state.itemradio === "unavailable"}
                        onChange={this.handleRadioChange.bind(this)}
                      />
                    </Form.Field>
                    <Form.Field className="availabilitycontrol">
                      <Radio
                        label="Hide From Menu"
                        name="radioGroup"
                        value="hide"
                        checked={this.state.itemradio === "hide"}
                        onChange={this.handleRadioChange}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row unstackable divided="vertically">
                  <Grid.Column width={8} centered>
                    <p className="newItemHeaders">
                      Menu Category{" "}
                      <Icon
                        className="asterisk"
                        name="asterisk"
                        size="tiny"
                        color="red"
                      />
                    </p>
                    <Dropdown
                      options={catdropdown}
                      placeholder="Select a Category..."
                      id="category"
                      search
                      selection
                      fluid
                      allowAdditions
                      defaultValue={item.group}
                      value={latestCategory}
                      onAddItem={this.handleAddition}
                      onChange={this.handleDropChange}
                    />
                    <br />
                    <Image
                      style={{ opacity: ".3" }}
                      wrapped
                      size="tiny"
                      src={applogo}
                    ></Image>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <p className="newItemHeaders">Photo (auto-save)</p>
                    {/*compressingimage*/}
                    <Dropzone onDrop={this.onDrop.bind(this)}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="photocontrol" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Image
                            size="small"
                            className="newItemPhoto"
                            src={img}
                          ></Image>
                        </div>
                      )}
                    </Dropzone>
                    <br />
                    <p className="newItemHeaders">Instagram Credit</p>
                    <Form.Input
                      id="igcredit"
                      onBlur={this.onAddChange.bind(this)}
                      className="additeminput"
                      placeHolder="Instagram Credit"
                      defaultValue={item.igcredit}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Form>
        </Modal.Content>
      </React.Fragment>
    );
  }
}

export { EditItem };
