import React, { Component } from "react";
import { Table, Icon, Dropdown, Input, Grid, GridColumn, Responsive } from "semantic-ui-react";
import { titleCase } from '../Functions/Functions.js'
import { updateUserAccess, updateInternalGC } from "../FirestoreFunctions.js";
import swal from "sweetalert";


class UserManagementTable extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userchanges: {},
            editdisabled: true,
            newinternaldata: {},
            newuser: {},
            editbuttons:
                <div><Icon name='pencil alternate' id='edit' onClick={this.toggledit} />Edit User Management</div>
        };
    }

    async componentDidMount() {
        const propinternaldata = this.props.internaldata
        const internaldata = this.props.internaldata
        const roles = [
            { key: 'admin', text: 'Admin', value: 'admin' },
            { key: 'manager', text: 'Manager', value: 'manager' },
        ]
        const users = await this.createData(internaldata)
        //console.log(users)
        this.setState({
            users: users,
            roles: roles,
            xrestaurant: this.props.xrestaurant
        })
    }



    static async getDerivedStateFromProps(nextProps, prevState) {
        //console.log(nextProps)
        //console.log(prevState)
        try {
            //    console.log(nextProps.newinternaldata.admin)
        } catch (error) { }
        try {
            //    console.log(prevState.newinternaldata.admin)
        } catch (error) { }


        //const newsize = Object.keys(prevState.newinternaldata).length
        if (nextProps.internaldata !== undefined && prevState.propinternaldata === undefined) {
            return {
                internaldata: nextProps.internaldata
            }
        } /*else if (newsize > 0) {
            return {
                internaldata: prevState.newinternaldata
            }
        } else if (prevState.internaldata !== undefined) {
            return {
                internaldata: prevState.internaldata
            }
        }*/
        if (nextProps.xrestaurant !== undefined && prevState.xrestaurant === undefined) {
            return {
                xrestaurant: nextProps.xrestaurant
            }
        }

    }

    async componentDidUpdate(newProps, prevState) {
        //console.log(newProps)
        //console.log(prevState)

        //console.log(users)
        if (newProps.internaldata !== prevState.propinternaldata) {
            //  console.log("9999 new")
            //Perform some operation here
            var users = await this.createData(newProps.internaldata)
            this.setState({
                internaldata: newProps.internaldata,
                propinternaldata: newProps.internaldata,
                users: users,
            });
        }
        if (newProps.xrestaurant !== prevState.xrestaurant) {
            //Perform some operation here
            this.setState({
                xrestaurant: newProps.xrestaurant,
            });
        }
    }

    onNewChange = ({ target }) => {
        const { newuser } = this.state
        newuser[target.id.toLowerCase()] = target.value.toLowerCase()
        //console.log(target.value)
        //console.log(target.id)
    }

    handleDropChange = (user, oldrole, e, target) => {
        const value = target.value
        //console.log(value)
        //console.log(e)
        //console.log(oldrole)
        const { newuser, newinternaldata, internaldata } = this.state
        if (oldrole === 'new') {
            newuser.role = value
        } else {
            const newsize = Object.keys(newinternaldata).length
            if (newsize > 0) {
                var tempnewinternaldata = JSON.parse(JSON.stringify(newinternaldata));
            } else {
                tempnewinternaldata = JSON.parse(JSON.stringify(internaldata));
            }
            const reducedtempnewinternaldata = Object.keys(tempnewinternaldata[oldrole]).reduce((object, key) => {
                if (key !== user[0]) {
                    console.log(tempnewinternaldata[oldrole])
                    object[key] = { name: tempnewinternaldata[oldrole][key].name }

                }
                return object
            }, {})
            if (tempnewinternaldata[value] === undefined) {
                tempnewinternaldata[value] = {}
            }
            tempnewinternaldata[value][user[0]] = { name: user[1].name }
            tempnewinternaldata[oldrole] = reducedtempnewinternaldata
            this.setState({ newinternaldata: tempnewinternaldata })
        }
        console.log(newinternaldata)
        console.log(internaldata)
        console.log(tempnewinternaldata)
    }

    onActionClick = async (user, role, e, data) => {
        const { xrestaurant, userchanges, internaldata, newinternaldata, newuser } = this.state
        const newsize = Object.keys(newinternaldata).length
        if (newsize > 0) {
            var tempnewinternaldata = JSON.parse(JSON.stringify(newinternaldata));
        } else {
            tempnewinternaldata = JSON.parse(JSON.stringify(internaldata));
        }

        console.log(xrestaurant)
        console.log(e)
        console.log(data)
        console.log(role)
        console.log(user)
        var access = false
        if (userchanges.xrestaurant === undefined) {
            userchanges.xrestaurant = xrestaurant
            userchanges.actions = {}
        }
        if (data.name === 'add') {
            access = true


            console.log(newinternaldata)
            console.log(internaldata)
            console.log(newuser)
            const size = Object.keys(newuser).length
            if (size < 3 && newuser.name !== '' && newuser.email !== '') {

                swal("Error", 'Required Information Missing!', "error");
                var update = false
            } else {
                userchanges.actions[newuser.email] = access
                console.log(newinternaldata)
                console.log(JSON.stringify(tempnewinternaldata[newuser.role]))
                console.log(JSON.stringify(tempnewinternaldata[newuser.role][newuser.email]))
                console.log(JSON.stringify([newuser.email]))
                //Object.assign(newinternaldata, internaldata)
                if (tempnewinternaldata[newuser.role] === undefined) {
                    tempnewinternaldata[newuser.role] = {}
                }

                tempnewinternaldata[newuser.role][newuser.email] = {}
                tempnewinternaldata[newuser.role][newuser.email].name = newuser.name

                console.log(tempnewinternaldata)
                console.log(internaldata)
                update = true
            }

        } else {

            userchanges.actions[user] = access
            delete tempnewinternaldata[role][user]
            update = true
            /*const size = Object.keys(newinternaldata).length
            if (size === 0) {
                console.log(newinternaldata)
                console.log('yep')
                const newmanagers = Object.keys(tempnewinternaldata[role]).reduce((object, key) => {
                    if (key !== user) {
                        console.log(internaldata[role])
                        object[key] = { name: internaldata[role][key].name }
                    }
                    return object
                }, {})
                Object.assign(newinternaldata, internaldata)

                newinternaldata[role] = newmanagers
                console.log(newinternaldata)
                update = true
            }
            else {
                console.log('here')
                delete newinternaldata[role][user]
                update = true
            }*/

        }

        //changes will be passed into the firestore userupdate
        if (userchanges.xrestaurant === undefined) {
            userchanges.xrestaurant = xrestaurant
            userchanges.actions = {}
        }
        userchanges.actions[user] = access
        //console.log(newinternaldata)
        if (update === true) {
            const tempusers = await this.createData(tempnewinternaldata, true)
            this.setState({ users: tempusers })
            const users = await this.createData(tempnewinternaldata)
            //console.log(internaldata)
            this.setState({ users: users, newinternaldata: tempnewinternaldata, userchanges: userchanges, newuser: {} })
        }


        //userchanges holds what needs to go to the useraccount
        //newinternaldata holds the temp userlist while in edit mode

    }

    toggledit = async (e, data) => {
        const { editdisabled, newinternaldata, internaldata, userchanges, xrestaurant } = this.state
        const newsize = Object.keys(newinternaldata).length
        if (newsize > 0) {
            var tempnewinternaldata = JSON.parse(JSON.stringify(newinternaldata));
        } else {
            tempnewinternaldata = JSON.parse(JSON.stringify(internaldata));
        }
        //console.log(internaldata)
        //console.log(tempnewinternaldata)
        const buttons = await this.buildButtons(data.id)
        //console.log(this.state)
        this.setState({ editdisabled: !editdisabled, editbuttons: buttons })
        if (data.id === 'save') {
            console.log('save')
            const size = Object.keys(tempnewinternaldata).length
            if (size !== 0) {
                var users = await this.createData(tempnewinternaldata)
                //need to write to db here.
                console.log(userchanges)
                console.log(userchanges.actions)
                for (const email in userchanges.actions) {
                    console.log(email)
                    console.log(userchanges.actions)
                    await updateUserAccess(email.toLowerCase(), userchanges.xrestaurant.toLowerCase(), userchanges.actions[email]) //updates user side

                }
                console.log(tempnewinternaldata)
                await updateInternalGC(xrestaurant, tempnewinternaldata, false) //updates restaurant side //false is no merge
                //updateUserAccess(email, xrestaurant, newstatus)
            } else {
                users = await this.createData(internaldata) //if saved without changes, need to rerun it to disable.
            }
            //internaldata = tempnewinternaldata
            this.setState({ users: users, internaldata: tempnewinternaldata },
                this.setState({ newinternaldata: {}, userchanges: {} }))
        } else {//if (data.id === 'cancel'){
            console.log('not save')
            // const tempusers = await this.createData(newinternaldata, true)
            this.setState({ users: null })
            const users = await this.createData(internaldata)
            this.setState({ users: users },
                this.setState({ newinternaldata: {}, userchanges: {} }))
            // } else { //edit

        }

    }
    buildButtons = async (id) => {

        if (id === 'edit') {
            return (
                <div><Icon name='close' id='cancel' color='red' onClick={this.toggledit} />Cancel
                    <Icon name='save' id='save' color='green' onClick={this.toggledit} />Save</div>
            )

        } else {
            if (id === 'cancel') {

            } else if (id === 'save') {
                //NEED TO WRITE TO BOTH USER PROFILE AND REMOVE THE RESTAURANT, AND THEN IF SUCCESSFUL, WRITE TO INTERNAL RESTAURANT. THAT WAY IN CASE IT FAILSE, IT WILL BE SEEN WHEN REFRESHED ON THE RESTAURANT SIDE
                const suceess = false// await updateUserAccess(    //await savePublicGC(this.state.xrestaurant, this.state.changedRestData)
                if (suceess) {
                    // this.setState({ restdata: this.state.changedRestData, changedRestData: {} })
                }

            }
            return (
                <div><Icon name='pencil alternate' id='edit' onClick={this.toggledit} />Edit User Management</div>
            )
        }
    }






    async createData(internaldata, clearnew) {
        //console.log(internaldata)
        if (internaldata !== undefined && clearnew) {

            const admins = await this.createAdmins(internaldata.admin)
            const managers = await this.createManagers(internaldata.manager)

            return (

                <React.Fragment>
                    {admins}
                    {managers}
                </React.Fragment>


            )
        } else if (internaldata !== undefined) {
            const admins = await this.createAdmins(internaldata.admin)
            const managers = await this.createManagers(internaldata.manager)
            const addnew = await this.createNew()
            return (

                <React.Fragment>
                    {admins}
                    {managers}
                    {addnew}
                </React.Fragment>


            )
        }

        else return null
    }


    async createAdmins(admin) {
        if (admin !== undefined) {
            const size = Object.keys(admin).length
            if (size > 0) {
                const { roles, editdisabled } = this.state
                return (
                    Object.entries(admin).map((a) =>
                        <Table.Row disabled={editdisabled} key={a[0].toLowerCase()}>

                            <Table.Cell className='userManagementName' >{titleCase(a[1].name)}</Table.Cell>
                            <Table.Cell className='userManagementEmail'>{a[0]}</Table.Cell>
                            <Table.Cell className='userManagementRoles' ><Dropdown options={roles} onChange={this.handleDropChange.bind(this, a, 'admin')} defaultValue={'admin'} /></Table.Cell>
                            <Table.Cell className='userManagementAction'><Icon name='close' color='red' onClick={this.onActionClick.bind(this, a[0], 'admin')} /></Table.Cell>
                        </Table.Row>
                    )

                )
            } return null

        } return null
    }

    async createManagers(manager) {
        if (manager !== undefined) {
            const size = Object.keys(manager).length
            if (size > 0) {
                const { roles, editdisabled } = this.state
                return (
                    Object.entries(manager).map((a) =>
                        <Table.Row disabled={editdisabled} key={a[0].toLowerCase()}>

                            <Table.Cell className='userManagementName' >{titleCase(a[1].name)}</Table.Cell>
                            <Table.Cell className='userManagementEmail'>{a[0]}</Table.Cell>
                            <Table.Cell className='userManagementRoles' ><Dropdown options={roles} onChange={this.handleDropChange.bind(this, a, 'manager')} defaultValue={'manager'} /></Table.Cell>
                            <Table.Cell className='userManagementAction'><Icon name='close' color='red' onClick={this.onActionClick.bind(this, a[0], 'manager')} /></Table.Cell>
                        </Table.Row>
                    )

                )
            } return null

        } return null
    }

    async createNew(clearnew) {
        const { roles, editdisabled } = this.state
        if (!editdisabled && !clearnew) {
            console.log('creating new')
            return (
                <Table.Row disabled={editdisabled} key={'newuser'}>

                    <Table.Cell className='centerOpeningHours' ><Input id='name' onBlur={this.onNewChange} transparent placeholder='New Member...' /> </Table.Cell>
                    <Table.Cell className='rightOpeningHours'><Input id='email' onBlur={this.onNewChange} transparent placeholder='Email...' /></Table.Cell>
                    <Table.Cell className='leftOpeningHours' ><Dropdown onChange={this.handleDropChange.bind(this, null, 'new')} options={roles} placeholder={'Role'} /></Table.Cell>
                    <Table.Cell className='rightOpeningHours'><Icon name='add' color='green' onClick={this.onActionClick.bind(this, null, null)} /></Table.Cell>
                </Table.Row>
            )
        } else {
            console.log('clearing')
            return null
        }
    }



    render() {
        const { users, editbuttons, internaldata } = this.state
        //console.log(internaldata)
        if (users !== undefined && users !== null) {
            //console.log(users)
            return (
                <React.Fragment>
                    {editbuttons}

                    <Table basic='very' singleLine >
                        <Table.Header fullWidth id='userheader'>
                            <Table.Row>
                                <Table.HeaderCell className='centerHeaderUsers'>Name</Table.HeaderCell>
                                <Table.HeaderCell className='centerHeaderUsers'>Email</Table.HeaderCell>
                                <Table.HeaderCell className='leftHeaderUsers'>Role</Table.HeaderCell>
                                <Table.HeaderCell className='rightHeaderUsers'>Remove</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {users}
                        </Table.Body>
                    </Table>
                </React.Fragment>
            )
        } else return "Select a Restaurant First"
    }
}

export { UserManagementTable }