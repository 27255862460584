import React, { Component } from "react";
import {
  Table,
  Form,
  Header,
  Input,
  Button,
  Modal,
  Icon,
  Grid,
  Divider,
  Checkbox,
  Label,
  Popup,
} from "semantic-ui-react";
import { titleCase, trimmnonbasilchar } from "../Functions/Functions.js";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { AddItem } from "./AddItem.js";
import Dropzone from "react-dropzone";
import { putStorageGC, savePublicGC } from "../FirestoreFunctions.js";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { handleImageUpload } from "../Functions/MediaFunctions.js";
import basillogoblack from "../images/Basil_menu_left_black.png";

const DragHandle = sortableHandle(() => <span id="draghandle">&equiv;</span>);

const SortableItem = sortableElement(({ value }) => (
  <li className="SortableItem">
    <DragHandle /> {titleCase(value)}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class MenuSettings extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      addModalOpen: false,
      editdisabled: true,
      changedRestMenu: {},
    };
  }

  async componentDidMount() {
    const { menudata, restmenu, catSet, menuid } = this.props;
    const buttons = await this.buildButtons();

    //const pm = this.props.menu
    //this.setState({ menuid, buttons, restmenu: this.props.restmenu, menudata: this.props.menudata, catSet: catSet, xrestaurant: this.props.xrestaurant })
    this.setState({ buttons, catSet });
  }
  /* async componentWillReceiveProps(props) {
 
         console.log(props)
         const { menudata, xmenu, restmenu, catSet } = this.props
         console.log(xmenu)
         this.setState({ menuheaders: null })
         const menuheaders = await this.buildMenuHeader(props.restmenu, props.catSet)
         console.log(menuheaders)
         //const pm = this.props.menu
         this.setState({ xmenu: props.xmenu, menuid: props.menuid, restmenu: props.restmenu, menudata: props.menudata, catSet: props.catSet, menuheaders: menuheaders })
         console.log(restmenu)
     }*/

  static async getDerivedStateFromProps(nextProps, prevState) {
    //console.log(nextProps)
    //console.log(prevState)
    var restmenus;
    var restmenu;

    if (nextProps.restdata !== prevState.restdata) {
      return {
        restdata: nextProps.restdata,
        menusettingsdisabled: nextProps.menusettingsdisabled,
      };
    }
    if (nextProps.xmenu !== prevState.xmenu) {
      return {
        xmenu: nextProps.xmenu,
        menusettingsdisabled: nextProps.menusettingsdisabled,
      };
    }
    if (nextProps.menusettingsdisabled !== prevState.menusettingsdisabled) {
      return {};
    }
  }
  async componentDidUpdate(newProps, prevState) {
    //console.log(newProps.menusettingsdisabled)
    //console.log(prevState.menusettingsdisabled)
    //console.log(this.props.menusettingsdisabled)
    if (
      this.props.xmenu !== prevState.xmenu &&
      newProps.restdata !== undefined &&
      this.props.xmenu !== undefined &&
      this.props.xmenu !== null
    ) {
      //console.log('propsfailed')
      //console.log(newProps.xmenu.value + ' ' + ' ' + this.props.xmenu.value)
      this.setState({
        xmenu: this.props.xmenu,
        menuheaders: null,
        changedRestMenu: {},
        editdisabled: true,
      }); //,buttons
      const menuheaders = await this.buildMenuHeader(this.props.xmenu);

      const builtCatSet = await this.buildMenuCat(this.props.catSet);

      this.setState({
        restmenu: newProps.restdata.menus[this.props.xmenu.value],
        menuheaders,
        builtCatSet,
        menusettingsdisabled: newProps.menusettingsdisabled,
      }); //HERE TEXT INSTEAD OF VALUE
    }
    if (this.props.menusettingsdisabled !== newProps.menusettingsdisabled) {
      const buttons = await this.buildButtons();
      this.setState({
        menusettingsdisabled: newProps.menusettingsdisabled,
        buttons,
      });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ catSet }) => ({
      catSet: arrayMove(catSet, oldIndex, newIndex),
    }));
  };

  onAddNewItem = async (id, newItem) => {
    //console.log(id)
    //console.log(newItem)
    if (
      newItem.item !== undefined &&
      newItem.group !== undefined &&
      newItem.item !== "" &&
      newItem.group !== "" &&
      id === "save"
    ) {
      this.setState({ addModalOpen: false });

      const saved = this.props.handleSavedItem(newItem, "add");
      //console.log(saved)
      if (saved) {
        this.setState({ addModalOpen: false });
        const builtCatSet = await this.buildMenuCat(this.props.catSet, false);

        //const pm = this.props.menu
        this.setState({ builtCatSet });
        //swal (success)
      } //else {error while adding}
    } //else (item is required)
  };

  handleOpen = async () => {
    this.setState({ addModalOpen: true });
    const builtCatSet = await this.buildMenuCat(this.props.catSet, true);

    //const pm = this.props.menu
    this.setState({ builtCatSet });
  };
  handleClose = async () => {
    this.setState({ addModalOpen: false });

    const builtCatSet = await this.buildMenuCat(this.props.catSet, false);

    //const pm = this.props.menu
    this.setState({ builtCatSet });
  };

  onDrop = async (acceptedFiles, rejectedFiles) => {
    //console.log(acceptedFiles)
    // console.log(rejectedFiles)
    if (acceptedFiles.length > 0) {
      //add swal for confirmation here
      Swal.fire({
        title: "Upload?",
        text:
          "Menu will uploaded immediately and cannot be cancelled. If you want to revert back to the previous menu, it has to be re-uploaded.",
        //type: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#006F32",
        cancelButtonColor: "#d33",
        confirmButtonText: "Upload",
        imageUrl: basillogoblack,
        imageWidth: 80,
        //imageHeight: 200,
        imageAlt: "Basil Menu",
        customClass: "successswal",
      }).then(async (value) => {
        //console.log(value.value)
        if (value.value) {
          await this.savePDF(acceptedFiles[0]);
          Swal.fire({
            title: "Done!",
            text: "PDF Uploaded!",
            imageUrl: basillogoblack,
            imageWidth: 80,
            //imageHeight: 200,
            imageAlt: "Basil Menu",
            customClass: "successswal",
            //animation: false
          });
        }
      });
    } else {
      //const errModal = await buildPDFErrModal()
      //Your PDF is likely too large. Currently, we only accept PDFs under .5MB.\n
      //We do this to keep our database cost as low as possible, and therefore provide you with the lowest hosting cost possible.Maybe more importantly, it ensures that your menus load as quickly as possible for your guests.Feel free to reach out if you feel like you are losing qualtiy when compressing and we will be happy to assist.',

      //swal("Error!", 'File not supported.', "error");
      Swal.fire({
        //type: 'error',
        customClass: "swalpdferror",
        title: "File Rejected",
        imageUrl: basillogoblack,
        imageWidth: 60,
        html:
          "Your PDF is likely too large. " +
          "Currently, we only accept PDFs under .5MB in order to provide you and your guests with the fastest loading times at the lowest cost." +
          "</br></br>Please compress the PDF and try again.",
        footer:
          'Need help compressing? Check out&nbsp;<a href="https://www.ilovepdf.com/compress_pdf" rel="noreferrer" target="_blank" >iLovePDF</a>',
      });
    }
  };
  savePDF = async (file) => {
    const { xrestaurant, xmenu } = this.props;

    const trimmed = await trimmnonbasilchar(xmenu.value);

    putStorageGC(xrestaurant, "menu", file, trimmed);
  };

  onEditChange = ({ target }) => {
    console.log(target);
    const { changedRestMenu } = this.state;
    if (target.id === "days" || target.id === "hours") {
      changedRestMenu[target.id] = target.value.replace(/\+/g, "\\n");
    } else if (
      target.id !== "pdf" &&
      target.id !== "hide" &&
      target.id !== "dynamicshow" &&
      target.id !== "noclick" &&
      target.id !== "external"
    ) {
      changedRestMenu[target.id] = target.value;
    } else {
      changedRestMenu[target.id] = target.checked;
    }
    //

    //console.log(changedRestMenu)
    //this.setState({ changedRestMenu })
  };

  buildMenuHeader = async (xmenu) => {
    try {
      //newProps.restdata.menus[newProps.xmenu.value]
      var restmenu = this.props.restdata.menus[xmenu.value];
      if (restmenu === undefined) {
        restmenu = {};
        restmenu.days = "";
        restmenu.hours = "";
      } else if (restmenu.days === undefined) {
        restmenu.days = "";
      } else if (restmenu.hours === undefined) {
        restmenu.hours = "";
      }

      const { editdisabled } = this.state;
      return (
        <React.Fragment>
          <Grid.Row>
            <Grid.Column verticalAlign="middle" width="6" className="pdfcolumn">
              <Icon
                id="pdftoggle"
                disabled={editdisabled}
                name="file pdf outline"
                style={{ verticalAlign: "top", color: "red" }}
              />
              <Checkbox
                disabled={editdisabled}
                id="pdf"
                defaultChecked={restmenu.pdf}
                onChange={this.onEditChange}
                toggle
              />
              <Dropzone
                disabled={editdisabled}
                onDrop={this.onDrop}
                maxSize={1048576}
                accept="application/pdf"
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="pdfupload" {...getRootProps()}>
                    <input {...getInputProps()} />
                    Click to Upload Menu
                  </div>
                )}
              </Dropzone>
            </Grid.Column>
            <Grid.Column width="10">
              <a
                href={"https://qr.basil.menu/" + this.props.xrestaurant}
                target="_blank"
                className="basilmenupagelink"
              >
                {" "}
                Click here to visit your Basil Menu page where can download your
                current menus.
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {/* <Grid.Column width='6'>
                            <Input disabled={true} id='menu' onBlur={this.onEditChange} transparent icon='font' iconPosition='left' placeholder='Public Name' className='conrolpanel' defaultValue={titleCase(restmenu.menu)} />
            </Grid.Column>*/}

            <Grid.Column width="6">
              <Input
                disabled={editdisabled}
                id="days"
                onBlur={this.onEditChange}
                transparent
                icon="calendar alternate outline"
                iconPosition="left"
                placeholder="Menu Days"
                className="conrolpanel"
                defaultValue={restmenu.days.replace(/\\n/g, "+")}
              />
            </Grid.Column>
            <Grid.Column width="5">
              <Input
                disabled={editdisabled}
                id="hours"
                onBlur={this.onEditChange}
                transparent
                icon="clock outline"
                iconPosition="left"
                placeholder="Menu Hours"
                className="conrolpanel"
                defaultValue={restmenu.hours.replace(/\\n/g, "+")}
              />
            </Grid.Column>
            <Grid.Column width="5">
              <Input
                disabled={editdisabled}
                id="sort"
                onBlur={this.onEditChange}
                transparent
                icon="sort numeric down"
                iconPosition="left"
                placeholder="Menu Sort"
                className="conrolpanel"
                defaultValue={restmenu.sort}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            {/*<Grid.Column width='11'>
                            <Input disabled={editdisabled} id='note' onBlur={this.onEditChange} transparent icon='newspaper outline' iconPosition='left' placeholder='Menu Note...' className='conrolpanel' defaultValue={titleCase(restmenu.note)} />
                        </Grid.Column>*/}
            <Grid.Column verticalAlign="middle" width="6">
              <Icon
                disabled={editdisabled}
                name="unlink"
                style={{ verticalAlign: "top", color: "blue" }}
              />
              <Checkbox
                label="Show Hours Only"
                disabled={editdisabled}
                id="noclick"
                className="highlighttoggle"
                defaultChecked={restmenu.noclick}
                onChange={this.onEditChange}
                toggle
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width="5">
              <Icon
                disabled={editdisabled}
                name="bullseye"
                style={{ verticalAlign: "top", color: "green" }}
              />
              <Checkbox
                label="Highlight Active"
                disabled={editdisabled}
                id="dynamicshow"
                className="highlighttoggle"
                defaultChecked={restmenu.dynamicshow}
                onChange={this.onEditChange}
                toggle
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width="5">
              <Icon
                id="hidetoggle"
                disabled={editdisabled}
                name="eye slash outline"
                style={{ verticalAlign: "top", color: "orange" }}
              />
              <Checkbox
                label="Hide Menu"
                disabled={editdisabled}
                id="hide"
                className="activetoggle"
                defaultChecked={restmenu.hide}
                onChange={this.onEditChange}
                toggle
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column verticalAlign="middle" width="16">
              <Icon
                id="externalicon"
                disabled={editdisabled}
                name="mail forward"
                style={{ verticalAlign: "top", color: "gray" }}
              />
              <Checkbox
                label="External Menu"
                disabled={editdisabled}
                id="external"
                className="activetoggle"
                defaultChecked={restmenu.external}
                onChange={this.onEditChange}
                toggle
              />

              <Input
                className="conrolpanel externalmenuurl"
                disabled={editdisabled}
                id="externalurl"
                onBlur={this.onEditChange}
                transparent
                placeholder="https://..."
                defaultValue={restmenu.externalurl}
              />
            </Grid.Column>
          </Grid.Row>
          {/*}
                    <Grid.Row >
                        <Grid.Column width='16'>
                            <Input disabled={editdisabled} id='note' onBlur={this.onEditChange} transparent icon='newspaper outline' iconPosition='left' placeholder='Menu Note...' className='conrolpanel' defaultValue={titleCase(restmenu.note)} />
                        </Grid.Column>
                    </Grid.Row>
                    */}
        </React.Fragment>
      );
    } catch (error) {
      console.log(error);
      return "Menu Header Information Not Available";
    }
  };

  buildMenuCat = async (catSet, open) => {
    //////////////////////////////////////////////
    ////// THIS SHOULD GO INTO ITS OWN COMP //////
    //////////////////////////////////////////////
    //return null
    //console.log(catSet)

    try {
      if (catSet !== undefined) {
        //console.log(this.state.addModalOpen)
        //console.log(open)
        return (
          //null /* ACTUAL CODE BLOCK
          <React.Fragment>
            <Divider style={{ width: "100vw" }} />
            <p>
              Reorganize menu categoies and items below. Click on an item to
              open the edit screen.{" "}
            </p>

            <Modal
              id="additemmodal"
              image
              trigger={
                <Button
                  key="mcat"
                  onClick={this.handleOpen}
                  inverted
                  className="menuorgbutton"
                >
                  <Icon name="add" /> Click here to add a new item
                </Button>
              }
              size="tiny"
              open={open}
            >
              <Modal.Header>
                {"Add New " + titleCase(this.props.xmenu.text) + " Item"}
              </Modal.Header>
              <AddItem
                xmenu={this.props.xmenu}
                catSet={catSet}
                onAddNewItem={this.onAddNewItem.bind(this)}
                handleClose={this.handleClose.bind(this)}
              />
            </Modal>
            {/*
                        <Modal image
                            trigger={<Button key='mcat' inverted className='menuorgbutton'>&equiv;   Click here to organize your menu categories</Button>}
                            size='tiny'
                        >
                            <Modal.Header>Categories</Modal.Header>
                            <Modal.Content>
                                <SortableContainer onSortEnd={this.onSortEnd} useDragHandle lockAxis='y' helperClass="SortableHelper">


                                    <ul className="SortableList">
                                        {catSet.map((cat, i) => (
                                            <SortableItem
                                                key={cat}
                                                value={cat
                                                }
                                                index={i}
                                                collection={0}
                                            />
                                        ))}
                                    </ul>
                                </SortableContainer>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color='red' inverted>
                                    <Icon name='close' /> Cancel </Button>
                                <Button color='green' inverted>
                                    <Icon name='checkmark' /> Save </Button>
                            </Modal.Actions>
                                            </Modal>
                                            */}
          </React.Fragment> //*/
        );
      } else return null;
    } catch (error) {
      console.log(error);
    }
  };

  buildButtons = async (id) => {
    // console.log(id)
    //console.log(this.state.editdisabled)

    if (id === "edit") {
      await this.props.disenablemenusettings(false);

      return (
        <div
          id="divmenuinfoedit"
          style={{ textAlign: "right", paddingLeft: "0.5rem" }}
        >
          <span>
            <Label
              id="save"
              className="menuinfosave"
              onClick={this.toggleeditmenuedit}
            >
              <Icon name="save" />
              Save
            </Label>
          </span>
          <span>
            <Label
              id="cancel"
              className="menuinfocancel"
              onClick={this.toggleeditmenuedit}
            >
              <Icon name="cancel" />
              Cancel
            </Label>
          </span>
        </div>
      );
    } else {
      if (id === "cancel") {
        this.setState({ changedRestMenu: {} });
        //<Label id='edit' className='menuinfoedit' onClick={this.toggleeditmenuedit}>
        //    <Icon name='pencil alternate' />Edit</Label>
        //console.log(this.state.restdata)
        //this.setState({ restdata: null },
        //    this.setState({ restdata: this.state.restdata }))
      } else if (id === "save") {
        console.log("here here");
        if (
          this.props.xmenu /*menuid*/ !== undefined &&
          Object.keys(this.state.changedRestMenu).length > 0
        ) {
          //const saved = this.props.handleSavedItem(this.state.changedRestMenu, 'change')
          var temprestdata = {};
          temprestdata.menus = {};
          const trimmed = await trimmnonbasilchar(this.props.xmenu.value);

          temprestdata.menus[trimmed] = this.state.changedRestMenu; //menuid
          if (this.props.xmenu.text !== undefined) {
            var menutext = this.props.xmenu.text;
          } else {
            menutext = this.props.xmenu.value;
          }
          temprestdata.menus[trimmed].menu = menutext;
          const suceess = await this.props.localSavePublicGC(temprestdata); //added this.props & local. took out this.props.xrestaurant as prop 1

          if (suceess) {
            const temprestmenu = await JSON.parse(
              JSON.stringify(this.state.changedRestMenu)
            );
            //console.log(this.state.restmenu)
            if (this.state.restmenu !== undefined) {
              var oldrestmenu = await JSON.parse(
                JSON.stringify(this.state.restmenu)
              );
            } else {
              oldrestmenu = {};
            }
            await Object.assign(oldrestmenu, temprestmenu);
            //console.log(oldrestmenu)
            const resetinternal = await this.props.saveRestData(
              oldrestmenu,
              trimmed
            );
            this.setState({ /*restmenu: oldrestmenu,*/ changedRestMenu: {} });
            Swal.fire({
              title: "Success!",
              text: "Menu Saved!",
              imageUrl: basillogoblack,
              imageWidth: 80,
              //imageHeight: 200,
              imageAlt: "Basil Menu",
              customClass: "successswal",
              //animation: false
            });
          }
        } else {
          swal(
            "Warning!",
            "No data was saved. Please keep in mind that attachments are auto-submitted so if that was your only edit, please ignore this message and visit your menu page to ensure the attachment was successfully uploaded.",
            "warning"
          );
        }
      }
      await this.props.disenablemenusettings(true);

      return (
        <div
          id="divmenuinfoedit"
          style={{ textAlign: "right", paddingLeft: ".5rem" }}
        >
          <Label
            id="edit"
            className="menuinfoedit"
            onClick={this.toggleeditmenuedit}
          >
            <Icon name="pencil alternate" />
            Edit
          </Label>
        </div>
      );
    }

    //this.setState({ editdisabled: !this.state.editdisabled })
  };

  toggleeditmenuedit = async (e, data) => {
    this.setState({
      menuheaders: null,
      editdisabled: !this.state.editdisabled,
    });
    //console.log(e)
    //console.log(data)

    const buttons = await this.buildButtons(data.id);
    //console.log(this.state.xmenu)
    //console.log(this.props.xmenu)
    const menuheaders = await this.buildMenuHeader(this.props.xmenu);
    const builtCatSet = await this.buildMenuCat(this.props.catSet, false);

    //const pm = this.props.menu
    this.setState({ menuheaders, buttons, builtCatSet });
  };

  render() {
    const {
      buttons,
      editdisabled,
      restmenu,
      menudata,
      builtCatSet,
      menuheaders,
      addModalOpen,
    } = this.state;
    const { restdata, xmenu } = this.props;
    //MOVE THIS TO ITS OWN COMPONENT. IT'S WORKING BUT INTERFERING WITH THE SORT.
    // console.log(addModalOpen)
    if (/*restmenu !== undefined && */ xmenu !== undefined && xmenu !== null) {
      if (editdisabled) {
        var popupvis = "hidden";
      } else {
        popupvis = "visible";
      }
      return (
        <React.Fragment>
          <Grid stackable columns="equal">
            <main className="stickcont">
              <div className="menuinfodiv">
                <Header
                  as="h3"
                  floated="left"
                  dividing
                  className="ctrlmenuheader"
                >
                  Menu Information
                  <Popup
                    basic
                    size="mini"
                    className="menuhelppop"
                    position="bottom center"
                    content={
                      <div>
                        <p>
                          Type + to add line breaks to opening days and hours.
                        </p>
                        <p>
                          Enable Show Hours Only to show the menu in the hours
                          table only. Menu will not be clickable.
                        </p>
                        <p>
                          Enable Highlight Active to only show the menu in the
                          top bar within operating hours. It will also be
                          highlighted in the hours table during operating hours.
                          Assumes that days and hours are well-fromatted.{" "}
                        </p>
                        <p>
                          Enable Hide Menu to hide it from both the top menu and
                          hours table.
                        </p>
                        <p>
                          Enable external menu and populate a URL to send
                          customers to an external menu. Remember to start your
                          URL with either http:// or https://
                        </p>
                      </div>
                    }
                    trigger={
                      <span>
                        <Icon
                          name="question"
                          color="grey"
                          size="mini"
                          className="questionicon"
                        />
                      </span>
                    }
                  />
                </Header>
              </div>

              <div className="menustick">{buttons}</div>
              <React.Fragment>{menuheaders}</React.Fragment>
            </main>
            <React.Fragment>{builtCatSet}</React.Fragment>
            <Divider fitted style={{ width: "100vw" }} />

            {/* <Divider /> */}
          </Grid>
        </React.Fragment>
      );
    } else return "Select a menu to edit first.";
  }
}

export { MenuSettings };
