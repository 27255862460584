import React, { Component, createRef } from "react";
import { firebase } from "../Firebase.js";
import {
  Ref,
  Sticky,
  Popup,
  Dimmer,
  Loader,
  Label,
  Input,
  Form,
  Header,
  Grid,
  TextArea,
  Button,
  Icon,
  Image,
  Modal,
  Segment,
  Divider,
  GridColumn,
  Checkbox,
} from "semantic-ui-react";
import {
  imgresize,
  imgoptimize,
  handleImageUpload,
} from "../Functions/MediaFunctions.js";
import {
  getRestaurantGC,
  savePublicGC,
  getStorageGC,
  putStorageGC,
  updateInternalGC,
} from "../FirestoreFunctions.js";
import Dropzone from "react-dropzone";
import swal from "sweetalert";
import Swal from "sweetalert2";
import basillogoblack from "../images/Basil_menu_left_black.png";
import basillogoblackopac from "../images/Basil_menu_left_black_opac.png";
import placeholderpopopac from "../images/placeholderabout_opac.png";
import placeholderaboutopac from "../images/placeholderabout_opac.png";
import merge from "lodash.merge";
import { CSVLink } from "react-csv";
import axios from "axios";
import { DateInput } from "semantic-ui-calendar-react";
import { asyncForEach } from "../Functions/Functions.js";

class GeneralEdit extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: "",
      changedRestData: {},
      internalRestData: {},
      imagespulled: false,
      editdisabled: true,
      checkininitloader: null,
      downloaddisp: { display: "none" },
      fetchdisp: {},
      csvfile: [
        [{ label: "No Data", key: "nodata" }],
        [{ nodata: "Select a Date First" }],
      ],
    };
  }

  async componentDidMount() {
    const editbuttons = await this.buildButtons();

    this.setState({
      // xrestaurant: this.props.xrestaurant,
      // restdata: this.props.restdata,
      // internaldata: this.props.internaldata,
      editbuttons,
      // loading: true
    });
  }

  static async getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps)
    // console.log(prevState)

    if (nextProps.restdata !== prevState.restdata) {
      return {
        restdata: nextProps.restdata,
      };
    }
  }
  async componentDidUpdate(newProps, prevState) {
    //console.log(newProps.restdata)
    //console.log(prevState.staterestdata)
    //console.log(this.props.restdata)
    if (
      prevState.statexrestaurant !== newProps.xrestaurant &&
      newProps.xrestaurant !== undefined &&
      this.state.imagespulled === false
    ) {
      var logoURL = await getStorageGC(
        newProps.xrestaurant.replace(/\s/g, "").toLowerCase(),
        "logo",
        "general"
      );
      var aboutURL = await getStorageGC(
        newProps.xrestaurant.replace(/\s/g, "").toLowerCase(),
        "about",
        "general"
      );
      var popURL = await getStorageGC(
        newProps.xrestaurant.replace(/\s/g, "").toLowerCase(),
        "pop",
        "general"
      );

      if (logoURL === undefined) {
        logoURL = basillogoblackopac;
      }
      if (aboutURL === undefined) {
        aboutURL = placeholderaboutopac;
      }
      if (popURL === undefined) {
        popURL = placeholderpopopac;
      }

      this.setState({
        logoURL,
        aboutURL,
        popURL,
        imagespulled: true,
        statexrestaurant: newProps.xrestaurant,
      });
    }
    if (
      prevState.staterestdata !== newProps.restdata &&
      prevState.stateinternaldata !== newProps.internaldata
    ) {
      const dimloader = await this.dimLoader(false);
      const guestLog = await this.guestLogFunction(
        newProps.restdata.sibguestlist,
        newProps.internaldata.sibapi
      );
      this.setState({
        stateinternaldata: newProps.internaldata,
        staterestdata: newProps.restdata,
        guestLog,
        //dimloader,
        //loading: false
      });
    }
  }

  togglegeneral = async (e, data) => {
    //console.log(data)
    this.setState({ staterestdata: null });
    const buttons = await this.buildButtons(data.id);
    this.setState({
      editdisabled: !this.state.editdisabled,
      editbuttons: buttons,
      staterestdata: this.props.restdata,
    });
  };
  buildButtons = async (id) => {
    if (id === "edit") {
      return (
        <div
          className="divgeneraledit"
          style={{ textAlign: "right", paddingLeft: "0.5rem" }}
        >
          <Label id="save" className="generalsave" onClick={this.togglegeneral}>
            <Icon name="save" />
            Save
          </Label>

          <Label
            id="cancel"
            className="generalcancel"
            onClick={this.togglegeneral}
          >
            <Icon name="cancel" />
            Cancel
          </Label>
        </div>
      );
    } else {
      if (id === "cancel") {
        const temprestdata = await JSON.parse(
          JSON.stringify(this.props.restdata)
        );
        this.setState({
          changedRestData: {},
          internalRestData: {},
          restdata: null,
        });
        const togg = await this.props.toggleAccordion();
      } else if (id === "save") {
        const suceess = await savePublicGC(
          this.props.xrestaurant,
          this.state.changedRestData
        );
        const internal = await updateInternalGC(
          this.props.xrestaurant,
          this.state.internalRestData,
          true
        );

        if (suceess && internal) {
          const temprestdata = await JSON.parse(
            JSON.stringify(this.state.changedRestData)
          );
          const newrestdata = await JSON.parse(
            JSON.stringify(this.props.restdata)
          );
          const merged = await merge(newrestdata, temprestdata);
          const tempinternaldata = await JSON.parse(
            JSON.stringify(this.state.internalRestData)
          );
          const newinternaldata = await JSON.parse(
            JSON.stringify(this.props.internaldata)
          );
          const mergedinternal = await merge(newinternaldata, tempinternaldata);
          //await Object.assign(oldrestdata, temprestdata)
          const setGeneralData = this.props.setGeneralData(
            merged,
            mergedinternal
          );

          this.setState({ /*restmenu: oldrestdata,*/ changedRestData: {} });
          // swal("Success!", 'Restaurant Information Updated!', "success");
          Swal.fire({
            title: "Done",
            text: "Restaurant Information Updated!",
            //type: 'warning',
            showCancelButton: false,
            confirmButtonColor: "#006F32",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            imageUrl: basillogoblack,
            imageWidth: 80,
            //imageHeight: 200,
            imageAlt: "Basil Menu",
            customClass: "successswal",
          });
        }
      }
      return (
        <div
          className="divgeneraledit"
          style={{ textAlign: "right", paddingLeft: ".5rem" }}
        >
          <Label id="edit" className="generaledit" onClick={this.togglegeneral}>
            <Icon name="pencil alternate" />
            Edit
          </Label>
        </div>
      );
    }
  };

  onEditChange = ({ target }) => {
    //console.log(target)
    const { changedRestData, internalRestData } = this.state;
    //console.log(changedRestData)
    //console.log(target.id.substring(7))
    //console.log(target.id.substring(9))
    if (
      target.id.substring(0, 7) === "primary" ||
      target.id.substring(0, 9) === "secondary"
    ) {
      if (internalRestData.contacts === undefined)
        internalRestData.contacts = {};
      if (target.id.substring(0, 7) === "primary") {
        if (internalRestData.contacts.primary === undefined)
          internalRestData.contacts.primary = {};
        internalRestData.contacts.primary[target.id.substring(7)] =
          target.value;
      } else if (target.id.substring(0, 9) === "secondary") {
        if (internalRestData.contacts.secondary === undefined)
          internalRestData.contacts.secondary = {};
        internalRestData.contacts.secondary[target.id.substring(7)] =
          target.value;
      }
    } else if (target.id == "sibapi") {
      internalRestData[target.id] = target.value;
    } else if (
      target.id === "address1" ||
      (target.id === "address2") |
        (target.id === "city") |
        (target.id === "state") |
        (target.id === "zip") |
        (target.id === "country")
    ) {
      if (changedRestData.address === undefined) changedRestData.address = {};
      changedRestData.address[target.id] = target.value;
    } else if (target.id === "basilmenu") {
      changedRestData[target.id] = !target.checked;
    } else if (
      target.id === "pop" ||
      target.id === "emailsub" ||
      target.id === "poppromo" ||
      target.id === "awaitsib"
    ) {
      changedRestData[target.id] = target.checked;
    } else {
      changedRestData[target.id] = target.value;
    }

    this.setState({ changedRestData, internalRestData });
  };

  async imgLoader(loading) {
    if (loading) {
      return (
        <Dimmer active page>
          <Loader content="Compressing image..." />
        </Dimmer>
      );
    } else return null;
  }
  async dimLoader(loading) {
    if (loading) {
      return (
        <Dimmer active page>
          <Loader content="Loading..." />
        </Dimmer>
      );
    } else return null;
  }

  async checkininitloaderfunc(loading) {
    if (loading) {
      return (
        <Dimmer active page>
          <Loader content="Configuring guest check-in with Sendinblue. This usually takes a few seconds... Don't forget to test the functionality before making it available to guests." />
        </Dimmer>
      );
    } else return null;
  }

  async downloadloaderfunc(loading) {
    if (loading) {
      return (
        <Dimmer active page>
          <Loader content="Downloading guest log from Sendinblue..." />
        </Dimmer>
      );
    } else return null;
  }
  async compileloaderfunc(loading) {
    if (loading) {
      return (
        <Dimmer active page>
          <Loader content="Compiling csv guest log. This shouldn't take long." />
        </Dimmer>
      );
    } else return null;
  }

  async onDrop(id, acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      const t = this;
      Swal.fire({
        title: "Upload?",
        text: "Photo will uploaded immediately and cannot be cancelled.",
        //type: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#006F32",
        cancelButtonColor: "#d33",
        confirmButtonText: "Upload",
        imageUrl: basillogoblack,
        imageWidth: 80,
        //imageHeight: 200,
        imageAlt: "Basil Menu",
        customClass: "successswal",
      }).then(async function (value) {
        //console.log(value)
        if (value.value === true || value === true) {
          //const logostring = String(URL.createObjectURL(basillogoblackopac))
          //console.log(id)
          //console.log(t.props.xrestaurant)
          var compressingimage = await t.imgLoader(true);
          t.setState({ compressingimage });
          const resized = await handleImageUpload(acceptedFiles[0]);
          //console.log(resized)
          putStorageGC(t.props.xrestaurant, id, resized)
            .then(() => {
              if (id === "logo") {
                t.setState({ logoURL: URL.createObjectURL(acceptedFiles[0]) });
              } else if (id === "about") {
                t.setState({ aboutURL: URL.createObjectURL(acceptedFiles[0]) });
              } else if (id === "pop") {
                t.setState({ popURL: URL.createObjectURL(acceptedFiles[0]) });
              }
            })
            .then(async () => {
              var compressingimage = await t.imgLoader(false);
              t.setState({ compressingimage });
              //swal("Success!", 'Image Uploaded!', "success");
              Swal.fire({
                title: "Success!",
                text: "Image Uploaded!",
                imageUrl: basillogoblack,
                imageWidth: 80,
                //imageHeight: 200,
                imageAlt: "Basil Menu",
                customClass: "successswal",
                //animation: false
              });
            });
        }
      });
    } else {
      swal("Error!", "File not supported", "error");
    }
  }

  handleDateChange = async (event, { name, value }) => {
    //console.log(name)
    //console.log(value)

    if (this.state.hasOwnProperty(name)) {
      this.setState(
        {
          [name]: value,
          pickerdate: new Date(Date.parse(value)),
          downloaddisp: { display: "none" },
          fetchdisp: {},
        },
        async () => {
          const guestLog = await this.guestLogFunction(
            this.props.restdata.sibguestlist,
            this.props.internaldata.sibapi
          );
          this.setState({
            guestLog,
          });
        }
      );
    }
  };

  downloadLog = async (event, { name, value }) => {
    var promptDate = new Date(Date.parse(this.state.date));
    var fetchDate = new Date(Date.parse(this.state.date));
    fetchDate.setDate(fetchDate.getDate() - 1);
    var plusDate = new Date(Date.parse(this.state.date));
    plusDate.setDate(plusDate.getDate() + 1);
    const stringdate = new Date(Date.parse(this.state.date))
      .toLocaleDateString("en-US", { timeZone: "UTC" })
      .replace(/\//g, "");

    //console.log("----")
    //console.log(this.state)
    //console.log(this.props)
    //console.log("----")
    //console.log(promptDate)
    //console.log(fetchDate)
    //console.log(plusDate)
    if (promptDate !== "Invalid Date") {
      const downloadloader = await this.downloadloaderfunc(true);
      this.setState({ downloadloader });
      try {
        const response = await axios.post(
          //basil call
          "https://checkin.basil.menu/getGuests",

          {
            xrestaurant: this.props.xrestaurant,
            fetchDate,
            promptDate,
            plusDate,
            sibguestlist: this.props.restdata.sibguestlist,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        //console.log(response)
        const csvfile = await this.compileCSV(promptDate, response);
        this.setState({
          csvfile,
          stringdate,
          downloaddisp: {},
          fetchdisp: { display: "none" },
        });
        const guestLog = await this.guestLogFunction(
          this.props.restdata.sibguestlist,
          this.props.internaldata.sibapi
        );
        this.setState({ guestLog, downloadloader: null });
        return csvfile;
      } catch (error) {
        console.log(error);
        return error;
      }
    }
  };

  compileCSV = async (promptDate, response) => {
    const headers = [
      { label: "Date", key: "date" },
      { label: "Time", key: "time" },
      { label: "Party Size", key: "size" },
      { label: "Full Name", key: "name" },
      { label: "Email Address", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Address Line 1", key: "address1" },
      { label: "Address Line 2", key: "address2" },
      { label: "City", key: "city" },
      { label: "State", key: "state" },
      { label: "Zip", key: "zip" },
    ];
    var data = [];
    console.log(response);
    await asyncForEach(response.data, async (guest) => {
      if (guest !== null) {
        if (guest.statistics.transacAttributes !== undefined) {
          await asyncForEach(
            guest.statistics.transacAttributes,
            async (visit) => {
              var visitDate = new Date(
                Date.parse(visit.VISIT_DATE)
              ).toLocaleDateString("en-US", { timeZone: "UTC" });
              console.log(
                "p: " +
                  promptDate.toLocaleDateString("en-US", { timeZone: "UTC" })
              );
              console.log("v: " + visitDate);
              console.log(this.props.restdata.sibguestlist);
              console.log(guest);
              console.log(visit);
              if (
                promptDate.toLocaleDateString("en-US", { timeZone: "UTC" }) ===
                visitDate
              ) {
                if (guest.attributes.ADDRESS2 !== undefined) {
                  var a2 = guest.attributes.ADDRESS2;
                } else a2 = "";

                if (
                  this.props.restdata.sibguestlist === visit.VISIT_LOCATION ||
                  visit.VISIT_LOCATION === undefined
                ) {
                  data.push({
                    date: visitDate,
                    time: visit.VISIT_TIME,
                    size: visit.VISIT_SIZE,
                    name:
                      guest.attributes.FIRSTNAME +
                      " " +
                      guest.attributes.LASTNAME,
                    email: guest.email,
                    phone: "+" + guest.attributes.SMS,
                    address1: guest.attributes.ADDRESS1,
                    address2: a2,
                    city: guest.attributes.CITY,
                    state: guest.attributes.STATE,
                    zip: guest.attributes.ZIP,
                  });
                }
              }
            }
          );
        }
      }
    });

    return [headers, data];
  };

  guestLogFunction = async (sibguestlist, sibapi, initiatesibstatus) => {
    const {
      downloaddisp,
      fetchdisp,
      csvfile,
      stringdate,
      pickerdate,
    } = this.state;
    //console.log(pickerdate)
    const { xrestaurant } = this.props;

    //console.log(initiatesibstatus)
    //console.log(sibguestlist)
    //console.log(sibapi)
    if (sibguestlist > 0 && sibapi !== undefined) {
      var minDate = new Date();
      var maxDate = new Date();
      minDate.setDate(minDate.getDate() - 32);

      return (
        <React.Fragment>
          <Divider fitted style={{ margin: "5px 10px 5px 10px" }} />
          <Grid.Row columns="equal" className="qrrow">
            <Grid.Column mobile="16" computer="8" tablet="8">
              <Form className="dateform">
                <span className="redirectlabel">
                  {" "}
                  <Icon name="calendar alternate outline" />
                  Download Guest Log
                </span>
                <DateInput
                  dateFormat="MM/DD/YYYY"
                  name="date"
                  placeholder="Date"
                  hideMobileKeyboard={true}
                  closable={true}
                  closeOnMouseLeave={false}
                  minDate={minDate}
                  maxDate={maxDate}
                  icon={false}
                  value={this.state.date}
                  onChange={this.handleDateChange}
                />
                <Button
                  style={fetchdisp}
                  color="black"
                  onClick={this.downloadLog}
                >
                  Get Guest Log
                </Button>
                <Button className="guestlogdownloadbutton" style={downloaddisp}>
                  <CSVLink
                    data={csvfile[1]}
                    headers={csvfile[0]}
                    filename={xrestaurant + " " + stringdate + " log.csv"}
                  >
                    Download
                  </CSVLink>
                </Button>
                <div style={{ height: ".8rem" }} />
                <a
                  className="checkinurl"
                  href={"https://checkin.basil.menu/" + xrestaurant}
                  target="_blank"
                >
                  {" "}
                  {"https://checkin.basil.menu/" + xrestaurant}
                </a>

                {/*https://checkin.basil.menu/*/}
              </Form>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    } else if (initiatesibstatus === 200) {
      return (
        <p>
          Guest Check-In is now enabled. Please refresh the Control Panel in
          order to download visitor logs.
        </p>
      );
    } else if (sibapi !== undefined) {
      return (
        <Button
          color="black"
          className="initCheckInFunc"
          onClick={this.initiateCheckIn}
        >
          Initiate Guest Check-In Functionality
        </Button>
      );
    } else {
      return <p>Enter a Sendinblue API Key to initiate Guest Check-In.</p>;
    }
  };

  initiateCheckIn = async (event, { name, value }) => {
    try {
      const signedstatement =
        "I, " +
        this.props.user.displayName +
        " (" +
        this.props.user.email +
        '), or another party designated by the operator, hereinafter referred to as “the Responsible Parties” as defined in the New York City Executive Order 202.61, acknowledge that we are using this system in an effort to comply with a requirement listed in the Standards for Responsible Indoor Food Services in New York City requiring the collection of the full name, address and phone number of one patron from each party who chooses to dine indoors. The Responsible Parties acknowledge that the information provided by Basil Menu LLC are based entirely on data provided by third parties and patron input and, therefore, Basil Menu LLC, including its owners, operators, employees, agents and affiliates, direct or indirect, hereinafter referred to as “Basil Menu", cannot and does not warrant or assume responsibility for the accuracy or completeness of the information provided by patrons in an effort to comply with the Executive Order referenced above. The Responsible Parties also acknowledge that Basil Menu is a separate entity and shall not be held responsible in any way for any contact information entered into the Responsible Parties’ page on Basil Menu’s platform, sent to the Responsible Parties’ Sendinblue account via Basil Menu’s API integration, or data residing within said Sendinblue account. We hereby also confirm that Basil Menu is not responsible to provide, or assist in providing, contact information to local authorities or any other necessary party in regards to contact tracing.' +
        " Except as provided above, the information and data are provided on an “as is” and “as available” basis. Basil Menu makes no warranty, express or implied, to the Responsible Parties, or to any authorized user or third party including any warranties of completeness, quality, accuracy, performance, compatibility, merchantability, and fitness for a particular purpose. The Responsible Parties acknowledge that Basil Menu is not responsible for the completeness or accuracy of any information or data provided and Basil Menu shall not be liable for any losses or claims resulting from the responsible parties’ reliance on any such information or data under any circumstances." +
        " I, " +
        this.props.user.displayName +
        ", hereby also confirm that I am authorized by management of " +
        this.props.fullxrestaurant +
        " (registered as " +
        this.props.xrestaurant +
        " in Basil Menu’s database) to agree to these terms and to use Basil Menu’s integration with Sendinblue to gather contact information from restaurant patrons in an effort to comply with New York City Executive Order 202.61, as it relates to indoor dining following COVID-19." +
        " The Responsible Parties shall perform regular audits of the contact information stored in Sendinblue in order to determine that the contact information made available is complete and accurate.";

      const { value: accept } = await Swal.fire({
        title: "End-User Agreement",
        input: "checkbox",
        inputValue: 0,
        confirmButtonColor: "#006F32",
        //imageUrl: basillogoblack,
        //imageWidth: 60,
        //imageAlt: 'Basil Menu',
        customClass: {
          container: "acknowledgement",
          content: "checkinacknowledgement",
          header: "checkinswalheader",
          input: "acknowledgecheck",
        },
        inputPlaceholder: signedstatement,

        //"By enabling Basil Menu's guest check-in functionality, I acknowledge that I am responsible for ensuring that both Basil Menu and Sendinblue are properly configured and that I am also responsible to make sure that all the contact info necessary is properly captured, regardless of if contact info is gathered through Basil Menu or manually on paper.",
        confirmButtonText: "Enable Check-In",
        inputValidator: (result) => {
          return (
            !result &&
            "Please accept the end-user agreement in order to enable guest check-in."
          );
        },
      });

      if (accept) {
        const checkininitloader = await this.checkininitloaderfunc(true);
        this.setState({ checkininitloader });

        const attributeresponse = await axios.post(
          //basil call
          "https://checkin.basil.menu/createAttributes",
          //checkinbasilmenu.web.app*
          //https://checkin.basil.menu/

          {
            xrestaurant: this.props.xrestaurant,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        console.log(attributeresponse);
        const sibattributes = attributeresponse.status;

        const listresponse = await axios.post(
          //basil call
          "https://checkin.basil.menu/createCheckInList",
          //checkinbasilmenu.web.app*
          {
            xrestaurant: this.props.xrestaurant,
            user: this.props.user,
            signedstatement,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        const sibguestlist = listresponse.data.id;
        console.log(listresponse);

        const guestLog = await this.guestLogFunction(
          null,
          null,
          listresponse.status
        );

        this.setState({ guestLog, checkininitloader: null });

        if (attributeresponse.status === 200 && listresponse.status === 200) {
          Swal.fire({
            title: "Success!",
            text:
              'You can now start using the guest check-in functionality. Make sure you do a test "check-in" before making it available to guests. Following your test, you can confirm everything is working as expected by either downloading and reviewing today\'s visitor log from the Basil Menu Control Panel (here), or review your lists and contacts wihtin Sendinblue.',
            //type: 'warning',
            showCancelButton: false,
            confirmButtonColor: "#006F32",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            imageUrl: basillogoblack,
            imageWidth: 80,
            //imageHeight: 200,
            imageAlt: "Basil Menu",
            customClass: "successswal",
          });
          console.log(attributeresponse);
          console.log("Check In Initiated");
        }
      } else {
        console.log("No Accept");
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      downloadloader,
      guestLog,
      checkininitloader,
      editdisabled,
      staterestdata,
      stateinternaldata,
      editbuttons,
      logoURL,
      aboutURL,
      popURL,
      compressingimage,
    } = this.state;
    const { xrestaurant } = this.props;
    //console.log(staterestdata)
    //console.log(restdata)
    if (
      xrestaurant !== undefined &&
      staterestdata !== null &&
      staterestdata !== undefined &&
      stateinternaldata !== null &&
      stateinternaldata !== undefined
    ) {
      if (staterestdata.address === undefined) staterestdata.address = {};
      if (stateinternaldata.contacts === undefined)
        stateinternaldata.contacts = {};
      if (stateinternaldata.contacts.primary === undefined)
        stateinternaldata.contacts.primary = {};
      if (stateinternaldata.contacts.secondary === undefined)
        stateinternaldata.contacts.secondary = {};
      return (
        <React.Fragment>
          <main>
            <div className="stick">{editbuttons}</div>
            <Segment className="generalsegment">
              {checkininitloader}
              {downloadloader}

              <Header as="h3" className="generalheaders" dividing>
                QR, Pop-Up, & Check-In
              </Header>
              <Grid stackable columns="equal">
                <Grid.Row columns="2" className="qrrow">
                  <Grid.Column width={8} className="basilurlcolumn">
                    <span className="redirectlabel">
                      <Icon name="home" /> Your Basil Menu URL
                    </span>
                    <div style={{ height: "8px" }} />
                    <a
                      className="basilurl"
                      href={"https://qr.basil.menu/" + xrestaurant}
                      target="_blank"
                    >
                      {" "}
                      {"https://qr.basil.menu/" + xrestaurant}
                    </a>
                    <Divider hidden className="basilurldivider" />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form>
                      <span className="redirectlabel">
                        <Icon name="mail forward" /> Redirect QR Code
                      </span>
                      <Popup
                        basic
                        size="mini"
                        position="bottom center"
                        className="menuhelppop"
                        content={
                          "By turning on redirect, all your visitors will be automatically redirected to any public URL. We recommend using this setting with caution as it can interupt your guest's experience. If turning it on, make sure you enter a valid URL first."
                        }
                        trigger={
                          <span>
                            <Icon
                              name="question"
                              color="grey"
                              size="mini"
                              className="questionicon"
                            />
                          </span>
                        }
                      />
                      <div style={{ height: "8px" }} />
                      <Checkbox
                        className="basilmenucheckbox"
                        disabled={editdisabled}
                        id="basilmenu"
                        defaultChecked={!staterestdata.basilmenu}
                        onChange={this.onEditChange}
                        toggle
                      />
                      <Input
                        className="controlpanelinput basilmenuredirect"
                        disabled={editdisabled}
                        id="redirect"
                        onBlur={this.onEditChange}
                        transparent
                        placeholder="https://..."
                        defaultValue={staterestdata.redirect}
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>

                <Divider fitted style={{ margin: "5px 10px 5px 10px" }} />
                <Grid.Row columns="equal" className="qrrow">
                  <Grid.Column mobile="16" computer="8" tablet="8">
                    <Form className="popform">
                      <span className="redirectlabel">
                        {" "}
                        <Icon name="announcement" />
                        Pop-Up
                      </span>
                      <Checkbox
                        label="Show"
                        className="pop"
                        disabled={editdisabled}
                        id="pop"
                        defaultChecked={staterestdata.pop}
                        onChange={this.onEditChange}
                        toggle
                      />
                      <Form.TextArea
                        disabled={editdisabled}
                        id="poptext"
                        onBlur={this.onEditChange}
                        rows="6"
                        className="controlpanel"
                        defaultValue={staterestdata.poptext}
                      ></Form.TextArea>
                    </Form>
                  </Grid.Column>
                  <Grid.Column mobile="16" computer="8" tablet="8">
                    <Form>
                      <div className="poppromodiv">
                        <span className="poppromolabel">
                          {" "}
                          <Icon name="photo" />
                          Pop-Up Photo
                        </span>
                        <Checkbox
                          className="poppromo"
                          disabled={editdisabled}
                          id="poppromo"
                          defaultChecked={staterestdata.poppromo}
                          onChange={this.onEditChange}
                          toggle
                        />
                      </div>
                      <Dropzone
                        onDrop={this.onDrop.bind(this, "pop")}
                        disabled={editdisabled}
                        accept={"image/jpeg, image/png"}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="photocontrol" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Image wrapped size="medium" src={popURL} rounded />
                          </div>
                        )}
                      </Dropzone>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal" className="qrrow">
                  <Grid.Column width="16">
                    <Checkbox
                      label="Sendinblue Email Opt-In"
                      className="emailsub"
                      disabled={editdisabled}
                      id="emailsub"
                      defaultChecked={staterestdata.emailsub}
                      onChange={this.onEditChange}
                      toggle
                    />
                  </Grid.Column>
                  <Grid.Column width="16">
                    <Checkbox
                      label="Provide Subscription Confirmation"
                      className="emailsub"
                      disabled={editdisabled}
                      id="awaitsib"
                      defaultChecked={staterestdata.awaitsib}
                      onChange={this.onEditChange}
                      toggle
                    />
                  </Grid.Column>
                  <Grid.Column mobile="16" computer="8" tablet="8">
                    <Input
                      icon="key"
                      iconPosition="left"
                      disabled={editdisabled}
                      id="sibapi"
                      onBlur={this.onEditChange}
                      transparent
                      placeholder="Sendinblue API Key"
                      className="controlpanel"
                      defaultValue={stateinternaldata.sibapi}
                    ></Input>
                  </Grid.Column>
                  <Grid.Column mobile="16" computer="8" tablet="8">
                    <Input
                      icon="numbered list"
                      iconPosition="left"
                      disabled={editdisabled}
                      id="siblistid"
                      onBlur={this.onEditChange}
                      transparent
                      placeholder="Sendinblue List ID"
                      className="controlpanel"
                      defaultValue={staterestdata.siblistid}
                    ></Input>
                  </Grid.Column>
                  <Grid.Column width="16">
                    <Input
                      icon="text cursor"
                      iconPosition="left"
                      disabled={editdisabled}
                      id="sibnote"
                      onBlur={this.onEditChange}
                      transparent
                      placeholder="Newsletter Note"
                      className="controlpanel"
                      defaultValue={staterestdata.sibnote}
                    ></Input>
                  </Grid.Column>
                </Grid.Row>
                {guestLog}
              </Grid>
              {/*} <Form>*/}
              <Header as="h3" className="generalheaders" dividing>
                Contact Information
              </Header>

              <Grid stackable columns="equal">
                <Grid.Column className="contactcolumns">
                  <Header as="h5" className="contactheaders">
                    <Icon
                      size="large"
                      name="building outline"
                      style={{ paddingLeft: "10px" }}
                    />
                    <Header.Content>Address</Header.Content>
                  </Header>
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput controlpanelinputnoicon"
                    id="name"
                    onBlur={this.onEditChange}
                    transparent
                    placeholder="Name"
                    defaultValue={staterestdata.name}
                  />

                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput controlpanelinputnoicon"
                    id="address1"
                    onBlur={this.onEditChange}
                    transparent
                    placeholder="Address Line 1"
                    defaultValue={staterestdata.address.address1}
                  />
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput controlpanelinputnoicon"
                    id="address2"
                    onBlur={this.onEditChange}
                    transparent
                    placeholder="Address Line 2"
                    defaultValue={staterestdata.address.address2}
                  />
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput controlpanelinputnoicon"
                    id="city"
                    onBlur={this.onEditChange}
                    transparent
                    placeholder="City"
                    defaultValue={staterestdata.address.city}
                  />

                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput controlpanelinputnoicon"
                    id="state"
                    onBlur={this.onEditChange}
                    transparent
                    placeholder="State"
                    defaultValue={staterestdata.address.state}
                  />
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput controlpanelinputnoicon"
                    id="zip"
                    onBlur={this.onEditChange}
                    transparent
                    placeholder="Zip Code"
                    defaultValue={staterestdata.address.zip}
                  />
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput controlpanelinputnoicon"
                    id="country"
                    onBlur={this.onEditChange}
                    transparent
                    placeholder="Country"
                    defaultValue={staterestdata.address.country}
                  />
                </Grid.Column>
                <Grid.Column className="contactcolumns">
                  <Header as="h5" className="contactheaders">
                    <Icon size="large" name="address book outline" />
                    <Header.Content>Contacts</Header.Content>
                  </Header>
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput"
                    id="primaryname"
                    onBlur={this.onEditChange}
                    transparent
                    icon="female"
                    iconPosition="left"
                    placeholder="Primary Contact Name"
                    defaultValue={stateinternaldata.contacts.primary.name}
                  />
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput"
                    id="primaryemail"
                    onBlur={this.onEditChange}
                    transparent
                    icon="mail"
                    iconPosition="left"
                    placeholder="Primary Contact Email"
                    defaultValue={stateinternaldata.contacts.primary.email}
                  />
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput"
                    id="primaryphone"
                    onBlur={this.onEditChange}
                    transparent
                    icon="phone"
                    iconPosition="left"
                    placeholder="Primary Contact Phone"
                    defaultValue={stateinternaldata.contacts.primary.phone}
                  />
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput"
                    id="secondaryname"
                    onBlur={this.onEditChange}
                    transparent
                    icon="male"
                    iconPosition="left"
                    placeholder="Secondary Contact Name"
                    defaultValue={stateinternaldata.contacts.secondary.name}
                  />
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput"
                    id="secondaryemail"
                    onBlur={this.onEditChange}
                    transparent
                    icon="mail"
                    iconPosition="left"
                    placeholder="Secondary Contact Email"
                    defaultValue={stateinternaldata.contacts.secondary.email}
                  />
                  <Input
                    disabled={editdisabled}
                    className="controlpanelinput"
                    id="secondaryphone"
                    onBlur={this.onEditChange}
                    transparent
                    icon="phone"
                    iconPosition="left"
                    placeholder="Secondary Contact Phone"
                    defaultValue={stateinternaldata.contacts.secondary.phone}
                  />
                </Grid.Column>
              </Grid>

              <Header as="h3" className="generalheaders" dividing>
                Social Media
              </Header>
              <Grid stackable columns="equal">
                <Grid.Row className="socialrow">
                  <Grid.Column width="3">
                    <Input
                      disabled={editdisabled}
                      className="controlpanelinput"
                      id="instagram"
                      onBlur={this.onEditChange}
                      transparent
                      icon="instagram"
                      iconPosition="left"
                      placeholder="Instagram"
                      style={{ color: "#E1306C" }}
                      defaultValue={staterestdata.instagram}
                    />
                  </Grid.Column>
                  <Grid.Column width="3">
                    <Input
                      disabled={editdisabled}
                      className="controlpanelinput"
                      id="facebook"
                      onBlur={this.onEditChange}
                      transparent
                      icon="facebook"
                      iconPosition="left"
                      placeholder="Facebook"
                      style={{ color: "#3b5998" }}
                      defaultValue={staterestdata.facebook}
                    />
                  </Grid.Column>
                  <Grid.Column width="3">
                    <Input
                      disabled={editdisabled}
                      className="controlpanelinput"
                      id="twitter"
                      onBlur={this.onEditChange}
                      transparent
                      icon="twitter"
                      iconPosition="left"
                      placeholder="Twitter"
                      style={{ color: "#1DA1F2" }}
                      defaultValue={staterestdata.twitter}
                    />
                  </Grid.Column>
                  <Grid.Column width="7" style={{ width: "100%" }}>
                    <Input
                      disabled={editdisabled}
                      className="controlpanelinput"
                      id="website"
                      onBlur={this.onEditChange}
                      transparent
                      icon="computer"
                      iconPosition="left"
                      placeholder="Website"
                      defaultValue={staterestdata.website}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Header as="h3" className="generalheaders" dividing>
                About
              </Header>
              <Grid unstackable="true" columns="equal">
                <Grid.Row>
                  <GridColumn>
                    <Form>
                      <Icon name="image" /> Restaurant Logo
                      <Dropzone
                        onDrop={this.onDrop.bind(this, "logo")}
                        disabled={editdisabled}
                        accept={"image/jpeg, image/png"}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="photocontrol" {...getRootProps()}>
                            <input {...getInputProps()} />

                            <Image
                              wrapped
                              size="medium"
                              src={logoURL}
                              floated="left"
                            />
                          </div>
                        )}
                      </Dropzone>
                    </Form>
                  </GridColumn>
                  <GridColumn>
                    {compressingimage}
                    <Form>
                      <Icon name="photo" /> About Image
                      <Dropzone
                        onDrop={this.onDrop.bind(this, "about")}
                        disabled={editdisabled}
                        accept={"image/jpeg, image/png"}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="photocontrol" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Image
                              wrapped
                              size="medium"
                              src={aboutURL}
                              rounded
                            />
                          </div>
                        )}
                      </Dropzone>
                    </Form>
                  </GridColumn>
                </Grid.Row>
                <Grid.Row>
                  {/*} <Input transparent icon='clock outline' iconPosition='left' placeHolder='Restaurant Hours' className='conrolpanel' defaultValue={restdata.openclose} />
                   */}
                  <Grid.Column stretched>
                    <Form>
                      <Icon name="building outline" /> About Header
                      <Input
                        className="controlpanelinput"
                        disabled={editdisabled}
                        id="aboutheader"
                        onBlur={this.onEditChange}
                        transparent
                        placeholder="About Header"
                        defaultValue={staterestdata.aboutheader}
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column stretched>
                    <Form>
                      <Icon name="building outline" /> About
                      <Form.TextArea
                        disabled={editdisabled}
                        id="about"
                        onBlur={this.onEditChange}
                        rows="6"
                        className="controlpanel"
                        defaultValue={staterestdata.about}
                      ></Form.TextArea>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column stretched>
                    <Form>
                      <Icon name="newspaper outline" /> Restaurant Disclaimer
                      (coming soon)
                      <Form.TextArea
                        disabled={editdisabled}
                        id="disclaimer"
                        onBlur={this.onEditChange}
                        className="controlpanel"
                        defaultValue={staterestdata.disclaimer}
                      ></Form.TextArea>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </main>
        </React.Fragment>
      );
    } else if (xrestaurant !== undefined) {
      return "Loading...";
    } else return "Select a Restaurant First";
  }
}

export { GeneralEdit };
