


import imageCompression from 'browser-image-compression';


async function handleImageUpload(input) {

    //const imageFile = event.target.files[0];
    const imageFile = input;

    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
        maxSizeMB: .2,
        //maxWidthOrHeight: 800,
        useWebWorker: true,
        maxIteration: 20
    }
    try {
        const compressedFile = await imageCompression(input, options);
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        return compressedFile; // write your own logic
    } catch (error) {
        console.log(error);
    }

}






///////////////////
///////////////////
///////////////////





const imgresize = async (img, height) => {
    return null
    /* try {
        import imagemin from 'imagemin'
import imageminMozjpeg from 'imagemin-mozjpeg'
import imageminPngquant from 'imagemin-pngquant'
import resizeImg from 'resize-img'
         const newimage = await resizeImg(img, {
             //width: 128,
             height: height
         });
         return newimage
     } catch (error) {
         console.log(error)
     }*/

}

const imgoptimize = async (img) => {
    return null
    /*try {
        const optimg = await imagemin(img, 'input/images', 'build/images', {
            plugins: [
                imageminMozjpeg(),
                imageminPngquant({
                    quality: [0.6, 0.8]
                })
            ]
        })/*.then(file => {
            cb(null, Object.assign(file, { original: buf }));
        }).catch(error => {
            cb(error);
        });
        return optimg
    } catch (error) {
        console.log(error)
    }*/

}







export { imgresize, imgoptimize, handleImageUpload }

/*
//    fs.writeFileSync('unicorn-128x128.png', image);
imagemin(['images/*.jpg'], 'build/images', { use: [imageminJpegtran()] }).then(() => {
    console.log('Images optimized');
});


function minify(file, cb) {
    fs.readFile(file.path, (err, buf) => {
        if (err) {
            cb(err);
            return;
        }
        imagemin([file.path], path.join(path.dirname(file.path), 'build'), {
            plugins: [
                imageminMozjpeg(),
                imageminPngquant()
            ]
        }).then(file => {
            cb(null, Object.assign(file, { original: buf }));
        }).catch(error => {
            cb(error);
        });
    });
}*/
