import React, { Component } from "react";
import {
  Table,
  Dropdown,
  Button,
  Accordion,
  Icon,
  Segment,
  Form,
  Divider
} from "semantic-ui-react";
import {
  titleCase,
  getIndexOfK,
  asyncForEach,
  compareValues,
  trimmnonbasilchar
} from "../Functions/Functions.js";
import {
  getRestaurantGC,
  getMenuListGC,
  getItemListGC,
  getStorageGC,
  getInternalGC
} from "../FirestoreFunctions.js";
import { GeneralEdit } from "./GeneralEdit.js";
import { MenuEdit } from "../MenuEdit/MenuEdit.js";
import { structurizemenu } from "../MenuEdit/MenuFunctions.js";
import { UserManagementTable } from "../UserManagement/UserManagementTable.js";
import { BatchUpload } from "./BatchUpload.js";

class ControlPanel extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      menupulled: false,
      internalpulled: false,
      restpickdisabled: false
    };
    //this.restaurantChange = this.restaurantChange.bind(this);
  }

  async componentDidMount() {
    //this.setState({ def: 0 })
    const managerof = this.props.managerof;
    const dropdownoptions = await this.createData(managerof);
    this.setState({
      dropdownoptions: dropdownoptions[0],
      def: dropdownoptions[1]
    });
    this.restaurantChange = this.restaurantChange.bind(this);
  }

  restaurantChange = async (e, data) => {
    var r = data.value.replace(/[\s+']/g, "").toLowerCase();
    //console.log(data);
    //console.log(e);
    const restdata = await getRestaurantGC(r);
    const internaldata = await getInternalGC(r);
    this.setState({
      restpickdisabled: true,
      xrestaurant: r,
      restdata: restdata,
      restmenus: restdata.menus,
      internalpulled: true,
      internaldata: internaldata,
      fullxrestaurant: data.value
    });
  };

  saveRestMenu = async (savedRestMenu, xmenuvalue) => {
    const { restdata } = this.state;
    //console.log(xmenuvalue)
    const xmenuvaluetrim = await trimmnonbasilchar(xmenuvalue);
    console.log(xmenuvaluetrim);
    //console.log(savedRestMenu)
    if (restdata.menus[xmenuvaluetrim] === undefined) {
      restdata.menus[xmenuvaluetrim] = {};
    }
    Object.assign(restdata.menus[xmenuvaluetrim], savedRestMenu);
    //console.log(restdata)
  };
  setGeneralData = async (rd, int) => {
    if (rd !== undefined) {
      this.setState({ restdata: rd });
    }
    if (int !== undefined) {
      this.setState({ internaldata: int });
    }
    //Object.assign(restdata.menus[xmenuvalue], savedRestMenu)
  };
  async createData(managerof) {
    var dropdownoptions = [];
    var def;
    // const m2 = managerof.sort(function (a, b) {
    //    console.log(a, b);
    //    return a.length - b.length;
    // });
    //const m3 = managerof.reverse()
    //console.log(managerof)
    //console.log(m2)
    //console.log(m3)
    managerof.map(mo => {
      dropdownoptions.push({
        key: mo.replace(/\s/g, "").toLowerCase(),
        value: mo.replace(/\s\_/g, "_"), //.toLowerCase()
        text: mo.replace(/\_/g, " ")
      });
    });
    if (Object.keys(dropdownoptions).length === 1) {
      //console.log(dropdownoptions[0].value)
      def = dropdownoptions[0].value;
      const dd = await this.restaurantChange(null, dropdownoptions[0]);
    } else {
      def = null;
    }

    return [dropdownoptions, def];
  }

  handleAccordionClick = async (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex, internalpulled, xrestaurant } = this.state;
    //console.log(activeIndex)
    const newIndex = activeIndex === index ? -1 : index;
    if (
      titleProps.name === "usermanagement" &&
      internalpulled === false &&
      xrestaurant !== undefined
    ) {
      const internaldata = await getInternalGC(xrestaurant);
      //console.log(internaldata)
      this.setState({ internalpulled: true, internaldata: internaldata });
    }

    this.setState({ activeIndex: newIndex });
  };

  toggleAccordion = async indx => {
    this.setState({ activeIndex: 0 });
    return true;
  };

  render() {
    const {
      dropdownoptions,
      def,
      xrestaurant,
      restdata,
      activeIndex,
      structuredmenu,
      xmenu,
      photoArray,
      catSet,
      menudata,
      menudropdown,
      restmenu,
      fullxrestaurant,
      internaldata,
      restpickdisabled
    } = this.state;

    //console.log(this.state)
    if (dropdownoptions !== undefined && restdata !== null) {
      return (
        <React.Fragment>
          <div className="controlCont">
            <input type="hidden" value="something" />
            <Dropdown
              placeholder="Select a Restaurant "
              fluid
              onChange={this.restaurantChange}
              search
              options={dropdownoptions}
              defaultValue={def}
              selection={true}
              disabled={restpickdisabled}
              className="restaurantdropdown"
            />
            <Accordion fluid>
              {/*
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                name={'qredit'}
                                onClick={this.handleAccordionClick}
                                className='ctrolaccordiontitle'
                            >
                                <Icon name='dropdown' /> {" QR & Pop-Up"}
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                                <QREdit
                                    xrestaurant={xrestaurant}
                                    restdata={restdata}
                                    internaldata={internaldata}
                                    setGeneralData={this.setGeneralData.bind(this)}
                                    //resetRestData={this.resetRestData.bind(this)}
                                    toggleAccordion={this.toggleAccordion.bind(this)}>
                                </QREdie>
                            </Accordion.Content>
                            */}
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                name={"general"}
                onClick={this.handleAccordionClick}
                className="ctrolaccordiontitle"
              >
                <Icon name="dropdown" /> {" Restaurant Information"}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <GeneralEdit
                  xrestaurant={xrestaurant}
                  fullxrestaurant={fullxrestaurant}
                  restdata={restdata}
                  internaldata={internaldata}
                  setGeneralData={this.setGeneralData.bind(this)}
                  user={this.props.user}
                  //resetRestData={this.resetRestData.bind(this)}
                  toggleAccordion={this.toggleAccordion.bind(this)}
                ></GeneralEdit>
              </Accordion.Content>
              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                name={"menumanagement"}
                onClick={this.handleAccordionClick}
                className="ctrolaccordiontitle"
              >
                <Icon name="dropdown" /> {" Menu Management"}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <MenuEdit
                  xrestaurant={xrestaurant}
                  restdata={restdata}
                  saveRestMenu={this.saveRestMenu.bind(this)}
                  /*structuredmenu={structuredmenu}
                                xmenu={xmenu}
                                photoArray={photoArray}
                                catSet={catSet}
                                menudata={menudata}
                                menudropdown={menudropdown}
        restmenu={restmenu}*/
                ></MenuEdit>
              </Accordion.Content>
              {/* ACTUAL CODE BLOCK
                        <Accordion.Title
                            active={activeIndex === 2}
                            index={2}
                            name={'usermanagement'}
                            onClick={this.handleAccordionClick}
                            className='ctrolaccordiontitle'
                        >
                            <Icon name='dropdown' /> {" User Management"}
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 2}>
                            <UserManagementTable internaldata={internaldata} xrestaurant={xrestaurant} />
                        </Accordion.Content>*/}

              {/* ACTUAL CODE BLOCK
                            <Accordion.Title
                                active={activeIndex === 3}
                                index={3}
                                name={'batchupload'}
                                onClick={this.handleAccordionClick}
                                className='ctrolaccordiontitle'
                            >
                                <Icon name='dropdown' /> {" Batch Menu Upload"}
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 3}>
                                <BatchUpload xrestaurant={xrestaurant} />
                            </Accordion.Content>*/}
            </Accordion>
            {/* <Divider />
                    STATISTICS*/}
            <Divider />
            Questions? Email us at{" "}
            <a href="mailto:niclas@basil.menu">niclas@basil.menu</a>
            <Divider />
            <div className="thankyoucontrolpanel">
              Thank you for choosing Basil Menu. We're a small family-owned
              business, based locally in New York City. We love to hear direct
              feedback from our customer, so if you have requests, comments, or
              improvement ideas, shoot us a note at{" "}
              <a href="mailto:info@basil.menu">info@basil.menu</a>. In the
              meantime, feel free to tag us on{" "}
              <a href="https://instagram.com/basilmenu" target="_blank">
                Instagram (@basilmenu)
              </a>{" "}
              and use #basilmenu to spread the word about our platform.
              <br />
              <br />
              Thank you!
              <br />
              Nic & Haleigh
            </div>
          </div>
        </React.Fragment>
      );
    } else return null;
  }
}

export { ControlPanel };
