function checkTime(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

function titleCase(str) {
    try {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    } catch (error) {
        return str
    }

}

async function trimmnonbasilchar(str) {

    return await str.replace(/[\s+'+&]/g, function (m) {
        return {
            " ": "_",
            "'": "",
            "&": "~"
        }[m];
    }).toLowerCase();
}

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

function compareValues(key, order = "asc") {

    return function (a, b) {

        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {

            // property doesn't exist on either object
            return 0;
        }

        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return order === "desc" ? comparison * -1 : comparison;
    };
}

/**
                 * Index of Multidimensional Array
                 * arr {?Array} - the input array
                 * {object} - the value to search
                 * {Array} 
                 */
function getIndexOfK(arr, k) {
    if (!arr) {
        return null
    }

    for (var i = 0; i < arr.length; i++) {
        var index = arr[i].indexOf(k);
        if (index > -1) {
            return [i, index];
        }
    }

    return null
}

function isObjEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

const joiner = (base, path) => {


    return base.charAt(base.length - 1) === '/'
        ? path
        : base + '/' + path
}


const slashtrim = (str) => {



    return str.charAt(str.length - 1) === '/'
        ? str.substring(0, str.length - 1)
        : str
}



const timeout = ms => new Promise(res => setTimeout(res, ms))

async function delay(t) {
    await timeout(t)
}





export { checkTime, titleCase, asyncForEach, compareValues, isObjEmpty, getIndexOfK, joiner, slashtrim, delay, trimmnonbasilchar }


