import React, { Component } from "react";
import { firebase } from "../Firebase.js";
import {
  Input,
  Form,
  Image,
  Radio,
  TextArea,
  Button,
  Modal,
  Accordion,
  Icon,
  Divider,
  Dropdown,
  Segment,
} from "semantic-ui-react";
import {
  titleCase,
  compareValues,
  getIndexOfK,
  asyncForEach,
  trimmnonbasilchar,
} from "../Functions/Functions.js";
import {
  getMenuListGC,
  getItemListGC,
  getStorageGC,
  putStorageGC,
  putMenuChange,
  savePublicGC,
  deleteFirestoreField,
} from "../FirestoreFunctions.js";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import Dropzone from "react-dropzone";
import swal from "sweetalert";
import Swal from "sweetalert2";
import basillogoblack from "../images/Basil_menu_left_black.png";
import createClone from "rfdc";
//import Accordion from 'react-bootstrap/Button';

import arrayMove from "array-move";
import { MenuSettings } from "./MenuSettings.js";
import { BuiltAccordionContent } from "./BuiltAccordionContent";
import rfdc from "rfdc";
import { truncate } from "lodash";
const firestore = firebase.firestore();

const DragHandle = sortableHandle(() => <span id="draghandle">&equiv;</span>);

const SortableItem = sortableElement(({ value }) => (
  <li
    className="SortableItem"
    onClick={() => {
      handleClick(value);
    }}
  >
    <DragHandle /> {value}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

//onClick = { myFunction() }
function handleClick(value) {
  // console.log(value)
}
//

async function findMaxKey(object) {
  // console.log(value)
  return Math.max(...Object.keys(object));
}

async function getKeyByValue(object, value) {
  if (Object.keys(object).find((key) => object[key] === value) === "group") {
    return true;
  } else {
    return false;
  }
}

const structurizemenu = async (xmenu, menudata) => {
  //console.log(xmenu);
  //console.log(menudata);
  var alldata = {}; //THIS WOULD BE A GOOD PLACE TO STORE ALL CATEGORIES FOR SORTING TOO. ALREADY LOOPING THROUGH
  var catSet = [];
  Object.entries(menudata).map((menu, index) => {
    if (
      menu[0].toLowerCase() === xmenu.value.toLowerCase() &&
      menu[1] !== undefined
    ) {
      Object.entries(menu[1].categories).map((group, gindex) => {
        const accordion = group[1].group; //accordion is going to have to come from the categories object instead so i can sort and populate summary
        if (alldata[accordion.toLowerCase()] === undefined) {
          alldata[accordion.toLowerCase()] = [];
        }

        //console.log(menu);

        Object.entries(menu[1].menuitems).map((item, iindex) => {
          //if menu[0]=== xmenu{}

          catSet.push(item[1].group.toLowerCase());

          if (item[1].group.toLowerCase() === accordion.toLowerCase()) {
            item[1].key = item[0];
            alldata[accordion.toLowerCase()].push(item[1]);
          }
        });
        if (group[1].summary !== undefined) {
          alldata[accordion.toLowerCase()].push(group[1].summary);
        } else {
          alldata[accordion.toLowerCase()].push("");
        }
      });
    }
  });

  catSet = [...new Set(catSet)];

  //before moving on to next accordion, sort alldata[accordion] by item sort -- sort(comparevalue())

  return [alldata, catSet];
};

class MenuEdit extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    // this.downloadMenus = this.downloadMenus.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  async componentDidMount() {
    this.setState({
      activeIndex: null,
      menusettingsdisabled: true,
    });
  }

  async disenablemenusettings(disabled) {
    this.setState({ menusettingsdisabled: disabled });
    //console.log(disabled);
  }

  async localSavePublicGC(passedrestdata) {
    const { xrestaurant } = this.props;

    const success = await savePublicGC(xrestaurant, passedrestdata);
    return success;
  }

  handleMenuAddition = async (e, { value }) => {
    const check = value.replace(/[^\w+\s+'+&]/g, "#%$^**");
    if (value === check) {
      const nospaces = await value.replace(/\s/g, "_").toLowerCase();

      this.setState((prevState) => ({
        menudropdown: [
          {
            value: value,
            key: value,
            text: value,
          },
          ...prevState.menudropdown,
        ],
        //menudata: [...prevState.menudata, [value]] //removed on 12.20.20
        // menuid: this.state.menudata[this.state.menudata.length - 1][0]
      }));
      //console.log(this.state.menudropdown);
    } else {
      Swal.fire({
        //type: 'error',
        customClass: "swalpdferror",
        title: "Characters not allowed",
        imageUrl: basillogoblack,
        imageWidth: 60,
        html:
          "Only alphanumeric characters, spaces, ampersand, apostrophes are allowed. " +
          "</br></br>" +
          "Please update your menu name and try again.",
      });
    }
  };

  menuopen = async (e, data) => {
    const { menudropdown } = this.state;
    // console.log(data)
    // console.log(data.defaultValue)
    //console.log(data.defaultValue.replace(/'/g, '').toLowerCase())

    const trimmed = await trimmnonbasilchar(data.defaultValue);
    // console.log(trimmed)
    if (this.props.restdata.menus !== undefined) {
      if (this.props.restdata.menus[trimmed] === undefined) {
        //console.log(this.props.restdata)
        //console.log(menudropdown)
        await menudropdown.shift();
      }
    }
  };

  menupick = async (e, data) => {
    //this.setState({ xmenu: null });

    for (const o in data.options) {
      if (data.options[o].value === data.value) {
        var text = data.options[o].text;
      }
    }

    const tempxmenu = {
      value: data.value,
      key: data.value,
      text: text,
    };
    this.setState({
      xmenu: tempxmenu,
      structuredmenu: {},
    });
    //console.log(data);
    //console.log(text);
    //console.log(this.state);
    const { xrestaurant } = this.state;

    if (this.state.menudata[tempxmenu.value] !== undefined) {
      //console.log(this.state);
      const strucmenu = await structurizemenu(tempxmenu, this.state.menudata);
      const structuredmenu = strucmenu[0];
      const catSet = strucmenu[1];
      //console.log(structuredmenu);
      const photoArray = await getItemListGC(xrestaurant, tempxmenu.key);

      for (const m in structuredmenu) {
        await asyncForEach(structuredmenu[m], async (item) => {
          const photoposition = getIndexOfK(photoArray, item.key);
          if (photoposition !== null) {
            const path = photoArray[photoposition[0]][1];
            item.picurl = await getStorageGC(
              xrestaurant,
              "item",
              path,
              tempxmenu.key
            );
          } else {
            //item.picurl = null
          }
        });
      }
      this.setState({
        structuredmenu: structuredmenu,
        //structv2: structuredmenu,
        xmenu: tempxmenu,
        //photoArray: photoArray,
        catSet: catSet,
        //restmenu,
        //menuid
      });
    }
  };

  static async getDerivedStateFromProps(nextProps, prevState) {
    //console.log(nextProps)
    //console.log(prevState)
    var restmenus;

    if (
      nextProps.xrestaurant !== undefined &&
      nextProps.xrestaurant !== prevState.controlxrestaurant &&
      prevState.controlxrestaurant === undefined
    ) {
      return {
        xrestaurant: nextProps.xrestaurant,
        restmenus: restmenus,
      };
    }
  }

  async componentDidUpdate(newProps, prevState) {
    //console.log(newProps)
    //console.log(prevState)
    if (newProps.xrestaurant !== undefined && prevState.xmenu === undefined) {
      // console.log(newProps)
      //console.log(prevState)

      /*
        Set Menu Dropdown - done
        Set Menu ID 
        Pull Menu for ID - done
        Structure Menu - done
        CatSet - done
        Pull Photos - done
        */
      const menudata = await getMenuListGC(newProps.xrestaurant);
      //console.log(menudata);
      var menudropdown = [];

      // const size = Object.keys(newProps.restdata.menus).length
      if (newProps.restdata.menus !== undefined) {
        Object.entries(newProps.restdata.menus).map((m, index) => {
          menudropdown.push({
            key: m[0].toLowerCase(),
            value: m[0].toLowerCase(),
            text: m[1].menu, // add a menukey to restdata and merry them up to get spacing
            sort: m[1].sort,
          });
        });
      }
      //////////////////

      /* const strucmenu = await structurizemenu(newProps.xmenu, menudata)
             const structuredmenu = strucmenu[0]
             const catSet = strucmenu[1]
    */

      //////////////////
      if (prevState.xmenu === undefined) {
        var tempxmenu = menudropdown[0];
        var menudef = menudropdown[0].value;
      } else {
        tempxmenu = prevState.xmenu;
      }
      if (tempxmenu !== null) {
        var strucmenu = await structurizemenu(tempxmenu, menudata); //&&
        var structuredmenu = strucmenu[0];
        var catSet = strucmenu[1];
        var photoArray = await getItemListGC(
          newProps.xrestaurant,
          tempxmenu.key
        );
      }
      //Perform some operation here
      this.setState({
        menudata,
        menudropdown,
        structuredmenu,
        catSet,
        photoArray,
        menudef,
        xmenu: tempxmenu,
        controlxrestaurant: newProps.xrestaurant,
      });
    }
  }

  onSortEnd = (name, e) => {
    const { structuredmenu } = this.state;

    const { oldIndex, newIndex } = e;
    const itemkey = e.nodes[oldIndex].node.lastChild.textContent;

    //console.log(structuredmenu[name])

    if (oldIndex !== newIndex) {
      //var currentitem
      for (const item in structuredmenu[name]) {
        var sort = parseInt(structuredmenu[name][item].sort);
        if (
          (sort > oldIndex && sort <= newIndex) ||
          (sort < oldIndex && sort >= newIndex)
        ) {
          if (oldIndex < newIndex) {
            console.log(
              "moved sort: " +
                sort +
                "   - affected by " +
                oldIndex +
                " being moved to " +
                newIndex
            );
            sort -= 1;
            console.log("to sort: " + sort);
          } else {
            console.log(
              "moved sort: " +
                sort +
                "   - affected by " +
                oldIndex +
                " being moved to " +
                newIndex
            );
            sort += 1;
            console.log("to sort: " + sort);
          }
          structuredmenu[name][item].sort = sort;
        }
      }
      var temparr = structuredmenu[name];
      structuredmenu[name][oldIndex].sort = newIndex;
      const sortedtemp = temparr.sort(compareValues("sort"));

      var newstruc = structuredmenu;
      newstruc[name] = temparr;

      this.setState({
        structuredmenu: newstruc,
      });
    }
  };

  onDrop(group, i, key, acceptedFiles) {
    const renamedAcceptedFiles = acceptedFiles.map((file) => {
      var ext = file.name.split(".").slice(-1)[0];
      var ext2 = file.name.split("." + ext).slice(0)[0];

      return new File([file], `${key}`, { type: file.type });
    }); //.${ext}
    this.updateThumbnail(renamedAcceptedFiles[0], group, i);
  }

  async updateThumbnail(file, group, i) {
    const { structuredmenu, xrestaurant } = this.state;

    var tempmenu = structuredmenu;
    //console.log(tempmenu[group][i].picurl)
    await swal("Upload new photo?", {
      buttons: {
        cancel: true,
        upload: true,
      },
    })
      .then((value) => {
        if (value === "upload") {
          putStorageGC(xrestaurant, "item", file);
        }
      })
      .then(
        (tempmenu[group][i].picurl = await getStorageGC(
          xrestaurant,
          "ctrlpanel",
          file,
          this.props.xmenu
        ))
      );
    this.setState({ structuredmenu: tempmenu });
  }

  saveRestData = async (savedRestMenu, xmenuvalue) => {
    const success = await this.props.saveRestMenu(savedRestMenu, xmenuvalue);
  };
  setKeyCheck = async (x) => {
    this.setState({ keycheck: x.key });
  };

  //ADD AND EDIT
  handleSavedItem = async (savedItem, type, formerCategory) => {
    //console.log(this.state.keycheck);
    //console.log(savedItem);
    if (savedItem.key !== this.state.keycheck || type === "add") {
      await this.setKeyCheck(savedItem);
      //console.log(this.state.keycheck);
      //console.log(savedItem);
      //console.log(type);
      //console.log(formerCategory);
      const { structuredmenu, menudata, catSet } = this.state;
      //const newsortid = structuredmenu[savedItem.group].length;
      //savedItem.sort = newsortid;
      //console.log(newsortid);
      //console.log(structuredmenu[savedItem.group][newsortid - 2].key);
      //console.log(savedItem.key);
      //if ( structuredmenu[savedItem.group][newsortid - 2].key !== savedItem.key ) {// not sure why i have this. prevents edits to last item

      ////////
      //IF EDIT, SETS CACHE SORT OF NEW CATEGORY AND SETS NEW ITEM TO ARRAY LENGTH. MIGHT WANT TO SET THIS TO A VAR TO FOLLOW THE COUNTER, SINCE .LENGTH IS NOT GREAT.
      if (type !== "delete") {
        var nextsort = 999;
        if (structuredmenu[savedItem.group] !== undefined) {
          const newsort = await asyncForEach(
            structuredmenu[savedItem.group],
            async (x, idx = 0) => {
              if (x !== undefined) {
                if (x.key !== undefined) {
                  x.sort = idx;
                  idx += 1;
                  nextsort = idx;
                }
              }
            }
          );
          savedItem.sort = nextsort;
        } else {
          nextsort = 0;
        }
      }

      try {
        //EDIT OR DELETE
        if (type !== "add" && structuredmenu[formerCategory] !== undefined) {
          const promises = await asyncForEach(
            structuredmenu[formerCategory],
            async (x, idx = 0) => {
              if (x !== undefined) {
                if (x.key !== undefined && x.key === savedItem.key) {
                  console.log(idx);
                  await structuredmenu[formerCategory].splice(idx, 1);
                }
                idx += 1;
              }
            }
          );

          //const completedpromise = await Promise.all(promises);
          //console.log(completedpromise);
          //console.log(menudata[this.state.xmenu.value]);
          //console.log(structuredmenu[formerCategory]);
          ////////////////////////

          //SETS CACHE SORT OF FORMER CATEGORY AND BUILDS CLOUD SORT IN NEWSORTWRITE
          var newsortwritemenuitems = {};
          const oldsort = await asyncForEach(
            structuredmenu[formerCategory],
            async (x, idx = 0) => {
              if (x !== undefined) {
                if (x.key !== undefined) {
                  x.sort = idx;
                  newsortwritemenuitems[x.key] = {};
                  newsortwritemenuitems[x.key].sort = idx;
                  idx += 1;
                }
              }
            }
          );
          var newsortwrite = { menuitems: newsortwritemenuitems };
          //console.log(newsortwrite);

          //console.log(menudata[this.state.xmenu.value]);
        }
      } catch (error) {
        console.log(error);
      }

      //IF EDIT OR ADD
      if (type !== "delete") {
        if (type === "add") {
          //SETS NEW ITEM TO ARRAY LENGTH. MIGHT WANT TO SET THIS TO A VAR TO FOLLOW THE COUNTER, SINCE .LENGTH IS NOT GREAT.
          //savedItem.sort = newsortid;
          //FINDS MAX KEY IN AN AWAIT TO ASSIGN KEY TO NEW ITEMS
          if (menudata[this.state.xmenu.value] !== undefined) {
            var maxitem = await findMaxKey(
              menudata[this.state.xmenu.value].menuitems
            );
          } else {
            menudata[this.state.xmenu.value] = {};
            menudata[this.state.xmenu.value].menuitems = {};
            maxitem = 0;
          }
          savedItem.key = maxitem + 1; //THIS IS WHERE THE KEY IS ASSIGNED

          if (structuredmenu[savedItem.group] === undefined) {
            structuredmenu[savedItem.group] = [];
          }
          if (menudata[this.state.xmenu.value] === undefined) {
            //console.log("fire1");
            menudata[this.state.xmenu.value] = {};
          }

          //console.log(menudata[this.state.xmenu.value]);
        }
        //BUILDS FIRST CATEGORY IF MENU IS BRAND NEW
        if (menudata[this.state.xmenu.value].categories === undefined) {
          //console.log("fire1");
          menudata[this.state.xmenu.value].categories = {};
          ///
          var catsave = {};
          catsave.sort = 0;
          catsave.group = savedItem.group; /////
          catsave.menu = this.state.xmenu.value;
          menudata[this.state.xmenu.value].categories[0] = catsave;
        } else {
          //IF CATEGORY IS FOUND, FIND THE CATEGORY ID
          //console.log("fire1");
          var catid;
          for (const c in menudata[this.state.xmenu.value].categories) {
            const map = menudata[this.state.xmenu.value].categories[c];
            var found = await getKeyByValue(map, savedItem.group);
            if (found === true) {
              catid = c;
            }
            //console.log(found);
          }
          //console.log(catid);
          //IF CATEGORY IS NEW ON AN EXISTING MENU, ADD IT TO THE END
          if (catid === undefined) {
            //console.log("fire1");
            const max = await findMaxKey(
              menudata[this.state.xmenu.value].categories
            );
            catsave = {};
            catsave.sort = max + 1;
            catsave.group = savedItem.group; /////
            catsave.menu = this.state.xmenu.value;
            menudata[this.state.xmenu.value].categories[max + 1] = catsave;
            //console.log(max);
            //console.log(catsave);
          }
        }
        //NOW ADD IT TO THE RENDERED CACHE MENU. FIRST POP OUT THE NOTE, ADD THE CATEGORY IN, AND THEN PUT THE NOTE BACK ON THE LAST SPOT
        //console.log(structuredmenu);
        if (structuredmenu[savedItem.group] === undefined) {
          structuredmenu[savedItem.group] = [];
        } else {
          var note = structuredmenu[savedItem.group].pop(); /////
        }
        structuredmenu[savedItem.group][nextsort] = savedItem; //here
        structuredmenu[savedItem.group].push(note);
        //console.log(structuredmenu);

        //console.log(this.state.xmenu);
        //console.log(this.props.xrestaurant);
        //console.log(savedItem);
        //console.log(structuredmenu[savedItem.group].length);
        //console.log(type);
        //BUILD SEND OBJECT WITH BOTH MENU ITEMS AND CATEGORIES
        var sendobj = {};
        sendobj.menuitems = {};
        sendobj.menuitems[savedItem.key] = savedItem;
        console.log(catsave);
        if (catsave !== undefined) {
          sendobj.categories = {};
          sendobj.categories[catsave.sort] = catsave;
        }
        //console.log(sendobj);
        //console.log(newsortwrite);
        //ASSIGN NEW SORT TO THE SENDOBJ
        Object.assign(sendobj, newsortwrite);
        //console.log(sendobj);
        await putMenuChange(
          this.props.xrestaurant,
          this.state.xmenu.value,
          sendobj
        );

        menudata[this.state.xmenu.value].menuitems[savedItem.key] = savedItem;

        if (catSet.indexOf(savedItem.group) === -1) {
          catSet.push(savedItem.group);
        }

        //console.log(structuredmenu[formerCategory]);
        //console.log(formerCategory);

        //if successful
        //return true;
      } else {
        var delobj = {};
        delobj.menuitems = {};
        delobj.menuitems[savedItem.key] = savedItem;
        const firestoredelete = deleteFirestoreField(
          this.props.xrestaurant,
          savedItem.key,
          false,
          this.state.xmenu.value,
          "menuitems"
        );
        //console.log(firestoredelete);
        await putMenuChange(
          this.props.xrestaurant,
          this.state.xmenu.value,
          newsortwrite
        );

        //console.log("firestore delete");
      }

      //console.log(structuredmenu[formerCategory]);
      this.setState({ structuredmenu: structuredmenu });
      //}
    }
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex, structuredmenu, xmenu } = this.state;
    const currentgroup = titleProps.children[1].props.children.toLowerCase();
    // console.log(currentgroup)
    // console.log(structuredmenu)
    if (currentgroup !== undefined) {
      const newIndex = activeIndex === index ? -1 : index;
      structuredmenu[currentgroup].sort(compareValues("sort"));
      this.setState({ activeIndex: newIndex, structuredmenu: structuredmenu });
    }
  };

  handleRadioChange = (e, { value }) => this.setState({ itemradio: value });

  render() {
    const {
      menuid,
      structv2,
      itemradio,
      catSet,
      restmenus,
      restmenu,
      menudata,
      activeIndex,
      menupick,
      menudropdown,
      structuredmenu,
      xmenu,
    } = this.state;
    const { xrestaurant, restdata } = this.props;
    //console.log(xmenu);
    //console.log(menudata);

    //console.log(structuredmenu);
    if (xrestaurant !== undefined && menudropdown !== undefined) {
      //console.log(this);
      //&& structuredmenu !== undefined) {
      return (
        <React.Fragment>
          <Segment>
            <p className="selectaddmenu">
              Start typing in the dropdown to add a new menu...
            </p>
            <Dropdown
              placeholder="Pick a menu to edit"
              fluid
              onChange={this.menupick}
              allowAdditions
              search
              selection
              onOpen={this.menuopen}
              options={menudropdown}
              defaultValue={menudropdown[0].value}
              onAddItem={this.handleMenuAddition}
              className="menudropdown"
            />

            <Divider />

            <MenuSettings
              handleSavedItem={this.handleSavedItem.bind(this)}
              saveRestData={this.saveRestData.bind(this)}
              localSavePublicGC={this.localSavePublicGC.bind(this)}
              disenablemenusettings={this.disenablemenusettings.bind(this)}
              menusettingsdisabled={this.state.menusettingsdisabled}
              xrestaurant={xrestaurant}
              catSet={catSet}
              restdata={restdata}
              restmenu={restmenu}
              menudata={menudata}
              xmenu={xmenu}
              menuid={menuid}
            />

            {
              ///* ACTUAL CODE BLOCK

              <React.Fragment>
                <Accordion.Accordion fluid className="menuitemaccordion">
                  {Object.entries(structuredmenu).map((items, index) => (
                    <React.Fragment key={index}>
                      <Accordion.Title
                        active={activeIndex === index}
                        index={index}
                        onClick={this.handleAccordionClick}
                      >
                        <Icon name="dropdown" />

                        <strong>{titleCase(items[0])}</strong>
                      </Accordion.Title>

                      <Accordion.Content active={activeIndex === index}>
                        <BuiltAccordionContent
                          handleSavedItem={this.handleSavedItem.bind(this)}
                          structuredmenu={structuredmenu}
                          activeIndex={activeIndex}
                          catSet={catSet}
                          index={index}
                          container={items[0]}
                          items={items[1]}
                          menusettingsdisabled={this.state.menusettingsdisabled}
                          menucategories={
                            this.state.menudata[this.state.xmenu.value]
                              .categories
                          }
                          xrestaurant={this.props.xrestaurant}
                          xmenu={this.state.xmenu}
                        />
                      </Accordion.Content>
                    </React.Fragment>
                  ))}
                </Accordion.Accordion>
              </React.Fragment>
            }
          </Segment>
        </React.Fragment>
      );
    } else if (xrestaurant !== undefined) {
      return "Loading...";
    } else return "Select a Restaurant First";
  }
}

export { MenuEdit };
